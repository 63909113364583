import styled, { css } from "styled-components";

export const StyledUserBox = styled.div<{ isSmall?: boolean }>`
  background: ${({ theme }) => theme.primary700};
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.white};

  > span {
    font-weight: bold;
    font-size: 1.625rem;
    line-height: 1.625rem;
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      width: 40px;
      height: 40px;

      > span {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    `}
`;
