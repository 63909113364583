import { PropertyAddress } from "../../api";
import { TestId } from "../../utils";
import { IconGeo } from "../Icons";
import { Location, LocationTitle, Details } from "./LocationDetails.styles";

type LocationDetailsProps = {
  propertyName: string;
  propertyAddress?: PropertyAddress;
};

const LocationDetails = ({
  propertyName,
  propertyAddress,
}: LocationDetailsProps) => {
  return (
    <Location data-testid={TestId.LocationDetails}>
      <IconGeo />
      <LocationTitle>{propertyName}</LocationTitle>

      {propertyAddress?.addressLine1 && (
        <Details>{propertyAddress?.addressLine1}</Details>
      )}
      {propertyAddress?.city &&
        propertyAddress?.stateOrRegion &&
        propertyAddress?.postalCode && (
          <>
            <br />
            <Details>{`${propertyAddress.city}, ${propertyAddress.stateOrRegion} ${propertyAddress.postalCode}`}</Details>
          </>
        )}
    </Location>
  );
};

export default LocationDetails;
