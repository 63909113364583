import styled, { css } from "styled-components";

export const Search = styled.div`
  position: relative;
  display: flex;

  > svg {
    position: absolute;
    padding: 14px 20px;
  }
`;

export const Input = styled.input<{ withError?: boolean }>`
  all: unset;
  border: 1px solid ${({ theme }) => theme.tertiary700};
  border-radius: 4px;
  padding: 12px 16px 12px 60px;
  width: 100%;
  height: 24px;

  &:focus {
    border: 2px solid ${({ theme }) => theme.primary500};
  }

  ${({ withError, theme }) =>
    withError === true &&
    css`
      border: 2px solid ${theme.negative500};
      &:focus {
        border: 2px solid ${({ theme }) => theme.negative500};
      }
    `}
`;
