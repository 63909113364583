import { SVGProps } from "react";
import { PRIMARY_500 } from "../../styles/colors";

const IconPhone = ({
  fill = PRIMARY_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29999 2C8.89999 2.4 10.3 3.6 11.1 5.2C11.9 6.4 12.5 8 12.5 8.6C12.5 9 11.9 10 11.5 10.6C10.5 12.2 9.69999 13.8 9.89999 15.4C10.1 16.2 10.9 18.6 13.7 21.4C16.5 24.2 18.9 25 19.7 25.2C19.9 25.2 20.1 25.2 20.3 25.2C21.7 25.2 23.1 24.4 24.5 23.6C25.1 23.2 26.1 22.6 26.5 22.6C27.3 22.6 28.9 23.4 29.9 24C31.3 25 32.7 26.2 33.1 26.8C32.7 28 29.1 31.6 26.1 32.4C25.7 32.6 25.3 32.6 24.9 32.6C20.9 32.6 14.9 28.2 10.9 24C6.49999 19.8 1.49999 13 2.69999 9C3.49999 6 7.09999 2.4 8.29999 2ZM8.49999 0C6.29999 0 1.89999 4.8 0.69999 8.4C-0.90001 14 6.09999 22.2 9.49999 25.6C12.7 28.8 19.5 34.8 24.9 34.8C25.5 34.8 26.1 34.8 26.7 34.6C30.5 33.4 35.7 28.6 35.1 26.6C34.5 24.8 31.3 22.6 31.1 22.4C30.9 22.2 28.3 20.6 26.7 20.6C26.5 20.6 26.5 20.6 26.3 20.6C24.7 20.8 21.9 23.2 20.5 23.2H20.3C19.3 23 17.3 22.2 15.3 20C13.1 17.8 12.3 16 12.1 15C11.7 13.6 14.5 10.6 14.7 9C14.9 7.4 13.1 4.6 12.9 4.2C12.7 3.8 10.7 0.6 8.69999 0.2C8.69999 0 8.49999 0 8.49999 0Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconPhone;
