const getInitials = (name?: string) => {
  if (!name) {
    return "";
  }
  const initials = name
    .split(" ")
    .reduce((accumulator, item) => `${accumulator}${item.charAt(0)}`, "");

  return initials.toUpperCase();
};

export { getInitials };
