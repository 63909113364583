import styled, { css } from "styled-components";

export const StyledButton = styled.button<{
  fullWidth?: boolean;
  disabled?: boolean;
  noBorders?: boolean;
  variant: "primary" | "secondary" | "tertiary";
}>`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  padding: 10px 16px;
  border-radius: 8px;
  border: none;
  box-shadow: none;
  cursor: pointer;

  ${({ variant }) =>
    variant === "primary" &&
    css`
      background: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.white};
    `}

  ${({ variant }) =>
    variant === "secondary" &&
    css`
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.primary};
      border: 1px solid ${({ theme }) => theme.primary};
    `}

	${({ variant }) =>
    variant === "tertiary" &&
    css`
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.secondary500};
      border: 1px solid ${({ theme }) => theme.secondary500};
    `}

	${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

	${({ disabled, variant }) =>
    variant === "primary" &&
    disabled &&
    css`
      background: ${({ theme }) => theme.primary200};
    `}

	${({ noBorders }) =>
    noBorders &&
    css`
      border: none;
    `}
`;
