import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { Detail, PoorConnectionDiv } from "./PoorConnection.styles";
import { IconPoorConnection } from "../Icons";

export type PoorConnectionProps = HTMLAttributes<HTMLDivElement>;

const PoorConnection = (props: PoorConnectionProps) => {
  const { t } = useTranslation();

  return (
    <PoorConnectionDiv {...props}>
      <IconPoorConnection />
      <Detail>{t("poor_connection.detail")}</Detail>
    </PoorConnectionDiv>
  );
};

export default PoorConnection;
