import styled from "styled-components";

export const StyledCheckbox = styled.div`
  position: relative;

  > input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    border: none;
    height: 100%;
  }
`;

export const LabelContainer = styled.div`
  padding-bottom: 32px;
  padding-top: 12px;
  display: flex;
  align-items: center;

  > label {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    z-index: 1;
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;
