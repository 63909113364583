import { SVGProps } from "react";
import { NEGATIVE_500 } from "../../styles/colors";

const IconLockWarning = ({
  fill = NEGATIVE_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.24 40.16L40 34.48V24.02C40 22.916 39.104 22.02 38 22.02H35V15C35 8.924 30.076 4 24 4C17.924 4 13 8.924 13 15V22.02H10C8.896 22.02 8 22.916 8 24.02V40.02C8 41.124 8.896 42.02 10 42.02H24.44C24.488 42.304 24.578 42.56 24.706 42.792C25.164 43.53 25.984 44.02 26.916 44.02C26.946 44.02 26.974 44.02 27.004 44.018H41C41.032 44.02 41.07 44.02 41.108 44.02C42.5 44.02 43.628 42.892 43.628 41.5C43.628 41.002 43.484 40.538 43.234 40.148L43.24 40.16ZM15 15C15 10.03 19.03 6 24 6C28.97 6 33 10.03 33 15V22.02H15V15ZM10 24.02H38V30.98L36.24 27.88C35.818 27.06 34.978 26.51 34.01 26.51C33.042 26.51 32.202 27.06 31.786 27.866L24.84 40.02H10V24.02ZM41 42.02H27C26.46 42.02 26.22 41.62 26.5 41.16L27.16 40.02L33.52 28.9C33.584 28.68 33.784 28.522 34.02 28.522C34.256 28.522 34.456 28.68 34.52 28.896L38 35.02L40 38.52L41.5 41.16C41.78 41.62 41.54 42.02 41 42.02ZM35 39.02C35 39.572 34.552 40.02 34 40.02C33.448 40.02 33 39.572 33 39.02C33 38.468 33.448 38.02 34 38.02C34.552 38.02 35 38.468 35 39.02ZM34 37.02C33.448 37.02 33 36.572 33 36.02V32.02C33 31.468 33.448 31.02 34 31.02C34.552 31.02 35 31.468 35 32.02V36.02C35 36.572 34.552 37.02 34 37.02Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconLockWarning;
