import { HTMLAttributes } from "react";
import { StyledHeader } from "./Header.styles";
import { IconRingLogo } from "../Icons";
import { ringUrl } from "../../utils";
import LanguageWidget from "../LanguageWidget";

type HeaderProps = HTMLAttributes<HTMLDivElement>;

const Header = (props: HeaderProps) => (
  <StyledHeader {...props}>
    <a aria-label="Ring" href={ringUrl}>
      <IconRingLogo />
    </a>
    <LanguageWidget />
  </StyledHeader>
);

export default Header;
