import { useCallback, useContext } from "react";
import { logger } from "../utils";
import { SessionTokenContext } from "../contexts";

export const useLogger = () => {
  const { encryptedSessionToken } = useContext(SessionTokenContext);

  const info = useCallback(
    (message: string, context?: Record<string, any>) => {
      if (encryptedSessionToken) {
        context = { ...context, sessionToken: encryptedSessionToken };
      }
      logger.info(message, context);
    },
    [encryptedSessionToken]
  );

  const error = useCallback(
    (message: string, context?: Record<string, any>) => {
      if (encryptedSessionToken) {
        context = { ...context, sessionToken: encryptedSessionToken };
      }
      logger.error(message, context);
    },
    [encryptedSessionToken]
  );

  return {
    info,
    error,
  };
};
