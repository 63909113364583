export const getCameraPermission = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: true,
  });
  stream.getVideoTracks().forEach((track) => track.stop());
};

export const getMicrophonePermission = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  });
  stream.getAudioTracks().forEach((track) => track.stop());

  return stream;
};
