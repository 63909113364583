import styled from "styled-components";

export const ThingsToCheck = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;

  > h3 {
    margin: 8px 0px;
  }

  > ul {
    margin: 0px;
    padding-left: 24px;
  }
`;
