import { Resident } from "../api";

export type MetricBaseData = {
  propertyId?: string;
  accessPointId?: string;
  contact?: Resident;
};

export const getMetricsBaseData = (data: MetricBaseData) => {
  return {
    propertyId: data?.propertyId,
    accessPointId: data?.accessPointId,
    residentId: data?.contact?.id,
  };
};
