import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Header, IconCallBusy } from "../../components";

const OngoingCallError: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <ErrorMessage
        title={t("ongoing_call_error.title")}
        description={t("ongoing_call_error.detail")}
      >
        <IconCallBusy></IconCallBusy>
      </ErrorMessage>
    </>
  );
};

export default OngoingCallError;
