import { useCallback, useEffect, useState } from "react";
import usePermissions from "./usePermissions";
import useHeap from "./useHeap";
import { MetricBaseData, getMetricsBaseData, metricEvents } from "../utils";
import { useLocation } from "react-router-dom";
import { useLogger } from "./useLogger";

/*
Low bitrate value was taken from WebRTC example that can be found here:
https://webrtc.github.io/samples/src/content/peerconnection/bandwidth
*/
export const LOW_BITRATE = 250;
export const MEDIA_ELEMENTS = 10;

const useConnectionStatus = (
  cameraIsEnabled: boolean,
  isConnected?: boolean
) => {
  const location = useLocation();
  const [data] = useState(location.state as MetricBaseData);
  const [bitrate, setBitrate] = useState(0);
  const [withPoorConnection, setWithPoorConnection] = useState(false);
  const [bitrateHistory, setBitrateHistory] = useState<
    { enabled: boolean; bitrate: number }[]
  >([]);
  // Last instance of Inbound audio bytes received
  const [lastIabReceived, setLastIabReceived] = useState<number>();
  const { camera } = usePermissions();
  const { track } = useHeap();
  const logger = useLogger();

  const getBitrateValue = (report: any, lastResult: any) => {
    let bytes;

    if (report.type === "outbound-rtp" && report.kind === "video") {
      if (report.isRemote) {
        return;
      }
      const now = report.timestamp;
      bytes = report.bytesSent;
      if (lastResult && lastResult.has(report.id)) {
        const timeDifference = now - lastResult.get(report.id).timestamp;
        const bytesDifference = bytes - lastResult.get(report.id).bytesSent;
        const bitrate = (8 * bytesDifference) / timeDifference;
        return Math.round(bitrate * 100) / 100;
      }
    }
  };

  useEffect(() => {
    // If has poor connection track metric
    if (withPoorConnection) {
      track(metricEvents.poorConnection, getMetricsBaseData(data));
    }
  }, [withPoorConnection, track, data]);

  useEffect(() => {
    if (Math.floor(bitrate) > 0) {
      setBitrateHistory((prevArr) => [
        ...prevArr,
        { bitrate, enabled: cameraIsEnabled },
      ]);
    }
  }, [bitrate, cameraIsEnabled]);

  useEffect(() => {
    if (
      camera === "granted" &&
      isConnected &&
      bitrateHistory.length >= MEDIA_ELEMENTS
    ) {
      // To calculate poor connection we check the average of the last 10 records
      const partial = bitrateHistory.slice(
        bitrateHistory.length - MEDIA_ELEMENTS,
        bitrateHistory.length
      );
      const average =
        partial
          .map((item) => item.bitrate)
          .reduce((accumulator, value) => accumulator + value, 0) /
        MEDIA_ELEMENTS;

      const allEnabled = partial.every((item) => item.enabled === true);

      const hasPoorConnection = average <= LOW_BITRATE && allEnabled;

      // Only change value if is different from current value
      setWithPoorConnection((prev) =>
        prev !== hasPoorConnection ? hasPoorConnection : prev
      );
    }
  }, [isConnected, bitrateHistory, camera]);

  const listenToStats = useCallback(
    (peer: RTCPeerConnection) => {
      let lastResult: any;
      window.setInterval(() => {
        if (!peer) {
          return;
        }

        peer.getStats().then((res: RTCStatsReport) => {
          res.forEach((report) => {
            if (report.type === "outbound-rtp") {
              if (report.kind === "video") {
                logger.info("Web RTC outbound video", { report });
              } else if (report.kind === "audio") {
                logger.info("Web RTC outbound audio", { report });
              }
            } else if (
              report.type === "inbound-rtp" &&
              report.kind === "audio"
            ) {
              setLastIabReceived(report?.bytesReceived);
              logger.info("Web RTC inbound audio", { report });
            }
            const bitrate = getBitrateValue(report, lastResult);
            if (bitrate !== undefined) {
              setBitrate(bitrate);
            }
          });
          lastResult = res;
        });
      }, 500);
    },
    [logger]
  );

  return { withPoorConnection, listenToStats, lastIabReceived };
};

export default useConnectionStatus;
