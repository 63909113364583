import styled, { css } from "styled-components";

export const Page = styled.div`
  min-height: 100%;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.tertiary300};
`;

export const Devices = styled.div`
  background-color: ${({ theme }) => theme.neutral50};
  padding: 0px 24px;
  max-width: 782px;
  margin: auto;

  @media screen and (min-width: 1024px) {
    padding: 0px 40px;
  }
`;

export const Content = styled.div`
  padding: 32px 24px;
  max-width: 782px;
  margin: auto;
  background-color: ${({ theme }) => theme.white};
  flex: 1;
  display: flex;
  flex-direction: column;
  > svg {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    padding: 32px 40px;

    > svg {
      display: block;
      height: 48px;
      width: 48px;
      margin-bottom: 16px;
    }
  }
`;

export const DeviceImage = styled.img`
  width: 100%;

  &.withMargin {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

export const Image = styled.img`
  max-width: 328px;
  height: 184px;
`;

export const Title = styled.h1`
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
`;

export const Headline = styled.h2`
  margin-top: 0px;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const ContentWithLogo = styled.div`
  display: flex;
  margin-bottom: 12px;

  > svg {
    flex-shrink: 0;
  }

  > div {
    padding-left: 24px;
    p:first-child {
      margin-bottom: 12px;
      font-weight: 600;
    }
  }
  @media screen and (min-width: 1024px) {
    > svg {
      display: none;
    }
    > div {
      padding-left: 0px;
    }
  }
`;

export const Text = styled.p<{ mb?: number; mt?: number; sm?: boolean }>`
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  ${({ sm }) =>
    sm &&
    css`
      font-size: 14px;
    `}

  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}px;
    `}

	${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px;
    `}
`;

export const Item = styled.li`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
`;

export const Step = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;

  > img {
    margin-bottom: 16px;
  }

  &.firstStep {
    padding-top: 30px;
  }
`;

export const Bold = styled(Text)`
  font-weight: 600;
`;

export const AccordionContent = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  ul {
    padding: 0px;
    margin: 0px;
    padding-left: 20px;
    &.margin20 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`;

export const DeviceHeader = styled.h3`
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  margin-top: 60px;
  margin-bottom: 15px;
`;

export const Support = styled.div`
  padding-top: 30px;
  padding-bottom: 16px;
`;

export const BoxContent = styled.div`
  margin-left: 16px;

  button {
    display: block;
    margin-top: 10px;
  }
`;

export const BoxTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
`;

export const BoxSubtitle = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
`;
