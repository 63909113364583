import { HTMLAttributes } from "react";
import { TestId } from "../../utils/testId";
import {
  Spinner,
  SpinnerContainer,
  LoadingText,
} from "./LoadingIndicator.styles";
import { useTranslation } from "react-i18next";

export type LoadingIndicatorProps = {
  color?: "white" | "neutral500";
} & HTMLAttributes<HTMLDivElement>;

const LoadingIndicator = ({
  color = "white",
  ...props
}: LoadingIndicatorProps) => {
  const { t } = useTranslation();
  return (
    <SpinnerContainer data-testid={TestId.LoadingIndicator} {...props}>
      <Spinner />
      <LoadingText color={color}>{t("loading")}</LoadingText>
    </SpinnerContainer>
  );
};

export default LoadingIndicator;
