import { useContext, useEffect, useState } from "react";
import {
  ROUTES,
  getMinutes,
  getSeconds,
  getTimeDifference,
  getTimeDifferenceInSeconds,
  metricEvents,
} from "../utils";
import { useNavigate } from "react-router-dom";
import { useHeap } from "./useHeap";
import { SessionTokenContext } from "../contexts/SessionTokenContext";

type UseSessionExpirationProps = {
  redirectOnSessionTimeout?: boolean;
  data?: { propertyId: string; accessPointId: string };
};

const useSessionExpiration = ({
  redirectOnSessionTimeout,
  data,
}: UseSessionExpirationProps) => {
  const [expirationDate, setExpirationDate] = useState<Date>();
  const [minutesLeft, setMinutesLeft] = useState<number>();
  const [secondsLeft, setSecondsLeft] = useState<number>();
  const [timeLeft, setTimeLeft] = useState<number>(); // In seconds
  const { tokenExpirationWrapper } = useContext(SessionTokenContext);
  const [tokenExpiration] = tokenExpirationWrapper;
  const navigate = useNavigate();
  const { track } = useHeap();

  useEffect(() => {
    if (tokenExpiration) {
      const expiration = new Date(Number(tokenExpiration) * 1000);
      setExpirationDate(expiration);
    }
  }, [tokenExpiration]);

  useEffect(() => {
    const redirect = () => {
      if (redirectOnSessionTimeout) {
        if (document.visibilityState === "visible") {
          track(metricEvents.timerExpired, {
            propertyId: data?.propertyId,
            accessPointId: data?.accessPointId,
          });
        }
        navigate({
          pathname: `../${ROUTES.SESSION_ERROR}`,
        });
      }
    };

    const getTime = () => {
      if (expirationDate) {
        const timeDiff = getTimeDifference(new Date(), expirationDate);
        const time = getTimeDifferenceInSeconds(new Date(), expirationDate);

        if (time && time <= 0) {
          redirect();
        }

        setTimeLeft(time);
        setMinutesLeft(getMinutes(timeDiff));
        setSecondsLeft(getSeconds(timeDiff));
      } else if (expirationDate === null) {
        redirect();
      }
    };

    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, [expirationDate, redirectOnSessionTimeout, data, navigate, track]);

  return {
    expirationDate,
    minutesLeft,
    secondsLeft,
    timeLeft,
    redirectOnSessionTimeout,
  };
};

export default useSessionExpiration;
