import { useEffect, useState } from "react";
import { StyledAccordion, Header, Content } from "./Accordion.styles";
import { IconArrow } from "../Icons";

type AccordionProps = {
  title: string;
  content: React.ReactNode;
  isOpen?: boolean;
};

const Accordion = ({ title, content, isOpen }: AccordionProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  return (
    <StyledAccordion>
      <Header isOpen={open} onClick={() => setOpen(!open)} role="button">
        <p>{title}</p>
        <IconArrow />
      </Header>
      <Content isOpen={open}>{content}</Content>
    </StyledAccordion>
  );
};

export default Accordion;
