import styled from "styled-components";

export const ActiveTimer = styled.div`
  padding: 16px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.positive500};
  background: ${({ theme }) => theme.positive100};
`;
