import { DirectoryResponse, getDirectory, getResidentsList } from "../../api";
import { useCallback, useContext, useEffect, useState } from "react";
import { SessionTokenContext } from "../../contexts/SessionTokenContext";
import { AxiosError } from "axios";
import { environment } from "../../utils";

export type useResidentsListProps = {
  data?: DirectoryResponse;
  error?: AxiosError;
  isLoading?: boolean;
  refetch: () => Promise<void>;
};

const useResidentsList = () => {
  const { sessionTokenWrapper, tokenExpirationWrapper } =
    useContext(SessionTokenContext);
  const [sessionToken] = sessionTokenWrapper;
  const [, setTokenExpiration] = tokenExpirationWrapper;
  const [data, setData] = useState<DirectoryResponse>();
  const [error, setError] = useState<AxiosError>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { mapOFF } = environment;

  const getResidentsData = useCallback(async () => {
    setIsLoading(true);
    try {
      if (mapOFF) {
        const response = await getResidentsList(
          sessionToken,
          setTokenExpiration
        );
        setData({
          addressName: response.propertyName,
          address: response.propertyAddress,
          propertyId: response.propertyId,
          accessPointId: response.accessPointId,
          people: response.residents,
        } as DirectoryResponse);
      } else {
        const response = await getDirectory(sessionToken, setTokenExpiration);
        setData(response);
      }
    } catch (err) {
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  }, [sessionToken, setTokenExpiration, mapOFF]);

  useEffect(() => {
    // initial call to get residents list
    if (sessionToken && !data) {
      getResidentsData();
    }
  }, [data, getResidentsData, sessionToken]);

  return {
    data,
    error,
    isLoading,
    refetch: getResidentsData,
  } as useResidentsListProps;
};

export default useResidentsList;
