const getTimeDifference = (date1: Date, date2: Date) => {
  return date2.getTime() - date1.getTime();
};

const getTimeDifferenceInSeconds = (date1: Date, date2: Date) => {
  return getTimeDifference(date1, date2) / 1000;
};

const getAbsTimeDifferenceInSeconds = (date1: Date, date2: Date) => {
  const duration = getTimeDifference(date1, date2) / 1000;
  return Math.abs(duration);
};
const getMinutes = (time: number) => {
  return Math.floor((time / 1000 / 60) % 60);
};

const getSeconds = (time: number) => {
  return Math.floor((time / 1000) % 60);
};

export {
  getTimeDifferenceInSeconds,
  getAbsTimeDifferenceInSeconds,
  getTimeDifference,
  getMinutes,
  getSeconds,
};
