import { SVGProps } from "react";

const IconChrome = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.6618 3.00618C37.6618 3.00618 58.1451 2.08698 69.0049 22.6758H35.9189C35.9189 22.6758 29.6749 22.4745 24.3411 30.0494C22.809 33.2288 21.162 36.5039 23.0102 42.9584C20.3478 38.4478 8.87561 18.472 8.87561 18.472C8.87561 18.472 16.9675 3.82012 37.6615 3.00618H37.6618Z"
        fill="#EF3F36"
      />
      <path
        d="M67.9418 55.2733C67.9418 55.2733 58.4899 73.4776 35.239 72.5775C38.1119 67.6073 51.7866 43.9254 51.7866 43.9254C51.7866 43.9254 55.0905 38.6202 51.1929 30.2123C49.2104 27.2915 47.1898 24.2367 40.6783 22.6086C45.9166 22.561 68.9475 22.6086 68.9475 22.6086C68.9475 22.6086 77.5754 36.9444 67.9418 55.2733Z"
        fill="#FCD900"
      />
      <path
        d="M7.52544 55.4169C7.52544 55.4169 -3.51601 38.1318 8.89471 18.4431C11.7581 23.4133 25.4328 47.0952 25.4328 47.0952C25.4328 47.0952 28.3824 52.6112 37.6042 53.4344C41.1189 53.1761 44.7769 52.9558 49.4504 48.139C46.8739 52.6974 35.3152 72.6159 35.3152 72.6159C35.3152 72.6159 18.5761 72.9224 7.52517 55.4169H7.52544Z"
        fill="#61BC5B"
      />
      <path
        d="M35.2296 72.7501L39.8834 53.3296C39.8834 53.3296 44.9972 52.9271 49.2875 48.2253C46.6251 52.9083 35.2296 72.7501 35.2296 72.7501Z"
        fill="#5AB055"
      />
      <path
        d="M22.0717 38.0935C22.0717 29.5325 29.0146 22.5896 37.5756 22.5896C46.1366 22.5896 53.0795 29.5325 53.0795 38.0935C53.0795 46.6547 46.1366 53.5974 37.5756 53.5974C29.0146 53.5878 22.0717 46.6547 22.0717 38.0935Z"
        fill="white"
      />
      <path
        d="M24.6667 38.0935C24.6667 30.9689 30.4412 25.1848 37.5756 25.1848C44.7001 25.1848 50.4843 30.9594 50.4843 38.0935C50.4843 45.2183 44.7099 51.0024 37.5756 51.0024C30.4508 51.0024 24.6667 45.2183 24.6667 38.0935Z"
        fill="url(#paint0_linear_1660_5154)"
      />
      <path
        d="M68.9378 22.6185L49.7756 28.2397C49.7756 28.2397 46.8837 23.9973 40.6688 22.6185C46.0602 22.5897 68.9378 22.6185 68.9378 22.6185Z"
        fill="#EACA05"
      />
      <path
        d="M22.6368 42.2496C19.9456 37.5859 8.87561 18.4719 8.87561 18.4719L23.0676 32.5105C23.0676 32.5105 21.6119 35.508 22.1579 39.798L22.6366 42.2496H22.6368Z"
        fill="#DF3A32"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1660_5154"
          x1="37.5749"
          y1="25.3671"
          x2="37.5749"
          y2="50.2253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#86BBE5" />
          <stop offset="1" stopColor="#1072BA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default IconChrome;
