import { SVGProps } from "react";
import { PRIMARY_500 } from "../../styles/colors";

const IconArrow = ({
  fill = PRIMARY_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.2251 8.08878C19.455 7.84903 19.7957 7.75638 20.1131 7.85203C20.4227 7.9453 20.6557 8.20091 20.7309 8.51732C20.8046 8.82711 20.7179 9.15606 20.4978 9.3856L12.6363 17.5539C12.2861 17.9174 11.7149 17.9174 11.3646 17.5539L3.50254 9.38497C3.28286 9.15592 3.19618 8.82677 3.26989 8.5168C3.34517 8.20021 3.57825 7.94445 3.88801 7.85114C4.20565 7.75544 4.54647 7.84815 4.77595 8.08742L12.0008 15.5949L19.2251 8.08878ZM12.0005 16.7544L11.907 16.8516C11.9552 16.8016 12.0458 16.8016 12.0937 16.8514L12.0005 16.7544Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconArrow;
