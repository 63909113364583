import axios from "axios";
import { ROUTES, environment } from "../utils";
import { logger } from "../utils";

const { basePath, basename } = environment;
export const axiosInstance = axios.create({ baseURL: basePath });

axiosInstance.interceptors.response.use(
  (response) => {
    logger.info("API Call", response.config);
    return response;
  },
  (error) => {
    const baseURL = `${basename?.length ? `/${basename}` : ""}`;
    const params = new URLSearchParams(window.location.search);
    params.delete("session");
    logger.error("API Call Error", error.response);

    if (error?.response?.status === 403) {
      window.location.replace(
        `${baseURL}${ROUTES.SESSION_ERROR}?${params.toString()}`
      );
    }

    return Promise.reject(error);
  }
);
