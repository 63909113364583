import { useTranslation } from "react-i18next";
import {
  StyledWebCam,
  StyledGuestMedia,
  CameraDisabled,
  CameraUnavailable,
  Loader,
  YouOverlay,
  collapseTime,
} from "./GuestMedia.styles";
import { useEffect, useRef, useState } from "react";
import { NEUTRAL_200, WHITE } from "../../styles/colors";
import { IconCamera, IconCameraDisabled, IconCameraLarge } from "../Icons";
import { ControlState } from "../../pages/LiveView/LiveView";
import { TestId } from "../../utils";

type GuestMediaProps = {
  state?: ControlState;
  videoConstraints?: boolean | MediaTrackConstraints | undefined;
  guestMedia?: MediaStream;
  fullHeight?: boolean;
  thumbnail?: boolean;
};

const GuestMedia = ({
  state,
  videoConstraints,
  guestMedia,
  fullHeight = false,
  thumbnail = false,
}: GuestMediaProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isCollapsing, setIsCollapsing] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const videoElement = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!state || state === ControlState.Enabled) {
      setLoading(true);
    }
    if (state === ControlState.Disabled || state === ControlState.Unavailable) {
      setLoading(false);
    }

    if(videoElement.current && guestMedia) {
      videoElement.current.srcObject = guestMedia;
      videoElement.current.play().then(() => {
        setLoading(false);
      })
    }
  }, [state, guestMedia]);

  const toggleCollapse = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
    } else {
      setIsCollapsing(true);

      setTimeout(() => {
        setIsCollapsed(true);
        setIsCollapsing(false);
      }, collapseTime);
    }
  };

  return (
    <>
      {(!thumbnail ||
        state === ControlState.Enabled ||
        state === ControlState.Disabled) && (
        <StyledGuestMedia
          fullHeight={fullHeight}
          thumbnail={thumbnail}
          isCollapsed={isCollapsed || isCollapsing}
          onClick={toggleCollapse}
        >
          {loading && <Loader />}
          {thumbnail && (
            <YouOverlay isCollapsed={isCollapsed || isCollapsing}>
              {(isCollapsed || isCollapsing) &&
                (state === ControlState.Enabled ? (
                  <IconCamera fill={WHITE} />
                ) : (
                  <IconCameraDisabled />
                ))}
              <span>{t("live_view.you")}</span>
            </YouOverlay>
          )}

          {state === ControlState.Enabled && (
            <StyledWebCam
              isMirrored={(videoConstraints as MediaTrackConstraints).facingMode === "user"}
              playsInline
              ref={videoElement}
              muted
              width="100%"
              className={loading ? "hide" : ""}
              data-testid={TestId.CameraAvailable}
            />
          )}
          {state === ControlState.Disabled && !isCollapsed && (
            <CameraDisabled data-testid={TestId.CameraDisabled}>
              <IconCameraLarge></IconCameraLarge>
            </CameraDisabled>
          )}
          {state === ControlState.Unavailable && (
            <CameraUnavailable data-testid={TestId.CameraUnavailable}>
              <IconCameraDisabled width={40} height={40} fill={NEUTRAL_200} />
              <p>{t("live_view.camera_required")}</p>
            </CameraUnavailable>
          )}
        </StyledGuestMedia>
      )}
    </>
  );
};

export default GuestMedia;
