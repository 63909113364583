import { SVGProps } from "react";
import { NEUTRAL_500 } from "../../styles/colors";

const IconRingLogo = ({
  fill = NEUTRAL_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="51"
      height="32"
      viewBox="0 0 51 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50.8236 24.9427V12.3255C50.8236 12.3235 50.8236 12.3215 50.8236 12.3201C50.8195 8.68662 47.7685 5.72981 44.0157 5.72882C40.2659 5.72981 37.2139 8.68909 37.2124 12.3274V18.3224C37.2124 21.9608 40.2654 24.921 44.0177 24.921C45.6329 24.921 47.1719 24.3745 48.3972 23.3736V24.958C48.3952 27.2579 46.3378 29.201 43.9044 29.201C42.5611 29.201 41.2229 28.6087 40.4113 27.6556C40.4087 27.6526 40.4062 27.6502 40.4041 27.6472C40.1724 27.3767 39.8334 27.2219 39.471 27.2219C38.8022 27.2219 38.2579 27.7497 38.2579 28.3982C38.2579 28.6668 38.3544 28.93 38.5293 29.1389C38.5313 29.1419 38.5338 29.1448 38.5359 29.1473C39.8156 30.6538 41.8226 31.5532 43.9044 31.5532C47.7197 31.5532 50.8236 28.5929 50.8236 24.9545C50.8241 24.9501 50.8236 24.9466 50.8236 24.9427ZM48.3972 12.3274V18.3224C48.3972 20.6637 46.4329 22.5689 44.0177 22.5689C41.6026 22.5689 39.6382 20.6642 39.6382 18.3209V12.3274C39.6382 9.98614 41.6026 8.08097 44.0177 8.08097C46.4329 8.08097 48.3972 9.98565 48.3972 12.3274Z"
        fill={fill}
      />
      <path
        d="M26.8394 5.72839C23.084 5.72839 20.027 8.69014 20.0239 12.331C20.0239 12.3329 20.0239 12.3349 20.0239 12.3369V23.1978C20.0239 23.8473 20.5688 24.3756 21.2386 24.3756C21.9085 24.3756 22.4533 23.8473 22.4533 23.1973V12.3369C22.4533 9.99212 24.4207 8.08448 26.8389 8.08448C29.2571 8.08448 31.2245 9.99212 31.2245 12.3369V23.1978C31.2245 23.8473 31.7693 24.3756 32.4392 24.3756C33.109 24.3756 33.6539 23.8473 33.6539 23.1978V12.3369V12.3344C33.6534 8.69211 30.5958 5.72839 26.8394 5.72839Z"
        fill={fill}
      />
      <path
        d="M10.0687 6.94365C10.0646 6.94069 10.0606 6.93773 10.0565 6.93477C10.056 6.93428 10.0347 6.92048 10.0342 6.91999C8.88706 6.14136 7.5387 5.72938 6.15172 5.72938C6.15121 5.72938 6.1507 5.72938 6.15019 5.72938C6.14409 5.72889 6.13444 5.72839 6.12834 5.72839C2.5788 5.72938 0.00152463 8.50731 0.000508153 12.3201C-8.52948e-08 12.3255 0 12.3315 0 12.3369V23.1978C0 23.8473 0.544831 24.3756 1.21469 24.3756C1.88455 24.3756 2.42938 23.8473 2.42938 23.1978V12.3369C2.42938 9.75361 3.88193 8.08448 6.13037 8.08448C7.0452 8.08448 7.9214 8.35454 8.66496 8.86607C8.66953 8.86952 8.67461 8.87248 8.67919 8.87494C8.88045 9.00652 9.11424 9.07551 9.35667 9.07551C10.0265 9.07551 10.5719 8.54723 10.5719 7.89722C10.5719 7.52072 10.3843 7.16491 10.0687 6.94365Z"
        fill={fill}
      />
      <path
        d="M14.7424 6.77112C14.0741 6.77112 13.5303 7.29842 13.5303 7.94645V23.2036C13.5303 23.8517 14.0741 24.379 14.7424 24.379C15.4108 24.379 15.9546 23.8517 15.9546 23.2036V7.94645C15.9546 7.29792 15.4108 6.77112 14.7424 6.77112Z"
        fill={fill}
      />
      <path
        d="M14.7227 0C13.6727 0 12.8184 0.828401 12.8184 1.84653C12.8184 2.86466 13.6727 3.69257 14.7227 3.69257C15.7727 3.69257 16.6266 2.86417 16.6266 1.84653C16.6271 0.828401 15.7727 0 14.7227 0Z"
        fill="#1998D5"
      />
    </svg>
  );
};
export default IconRingLogo;
