import { SVGProps } from "react";
import { WHITE } from "../../styles/colors";

const IconCameraDisabled = ({
  fill = WHITE,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 12C12 11.4 12.4 11 13 11C13.6 11 14 11.4 14 12C14 12.6 13.6 13 13 13C12.4 13 12 12.6 12 12Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 4.39995C19.4 4.39995 19.3 4.39995 19.2 4.49995L16 6.39995V3.79995L18 1.79995C18.2 1.59995 18.2 1.29995 18 1.09995C17.8 0.899951 17.5 0.899951 17.3 1.09995L1 17.3C0.8 17.5 0.8 17.7999 1 18C1.2 18.2 1.5 18.2 1.7 18L4.7 15H15C15.6 15 16 14.5 16 14V11.6L19.2 13.5C19.25 13.5 19.3 13.525 19.35 13.55C19.4 13.575 19.45 13.6 19.5 13.6C19.8 13.6 20 13.4 20 13.1V4.89995C20 4.59995 19.7 4.39995 19.5 4.39995ZM5.7 14H15V4.69995L5.7 14ZM19 12.2L16 10.4V7.49995L19 5.69995V12.2Z"
        fill={fill}
      />
      <path
        d="M1 15H1.9L2.9 14H1V3.99995H12.9L13.9 2.99995H1C0.4 2.99995 0 3.49995 0 3.99995V14C0 14.5 0.4 15 1 15Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconCameraDisabled;
