import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Header, IconResidentBusy } from "../../components";

const ResidentUnavailable: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <ErrorMessage
        title={t("resident_unavailable.title")}
        description={t("resident_unavailable.detail")}
      >
        <IconResidentBusy></IconResidentBusy>
      </ErrorMessage>
    </>
  );
};

export default ResidentUnavailable;
