import styled from "styled-components";

export const BackNav = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.tertiary500};
  padding: 16px;
`;

export const BackButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  cursor: pointer;

  > svg {
    margin-right: 12px;
  }
`;
