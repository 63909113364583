import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActiveTimer } from "./Timer.styles";
import Button from "../Button/Button";
import { useSessionExpiration } from "../../hooks";
import { Modal } from "../";
import { ModalContent as Content } from "../../App.styles";

export type TimerProps = {};

const Timer = () => {
  const { t } = useTranslation();
  const [showModal, setshowModal] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);
  const { secondsLeft, minutesLeft, timeLeft } = useSessionExpiration({});

  useEffect(() => {
    if (
      timeLeft !== undefined &&
      timeLeft >= 60 &&
      timeLeft <= 61 &&
      !modalClosed
    ) {
      setshowModal(true);
    }
  }, [timeLeft, modalClosed]);

  const getTime = (num: number) => {
    const time = String(num);
    return time.length === 1 ? `0${time}` : time;
  };

  const getTimer = () => {
    return minutesLeft !== undefined && secondsLeft !== undefined
      ? ` ${getTime(minutesLeft)}:${getTime(secondsLeft)}`
      : "";
  };

  return (
    <>
      {timeLeft !== undefined && timeLeft <= 60 && (
        <ActiveTimer>{`${t(
          "timer.session_expires"
        )} ${getTimer()}`}</ActiveTimer>
      )}

      <Modal
        isOpen={showModal}
        header={t("timer.warning")}
        content={
          <Content>
            <h3>{t("timer.still_there")}</h3>
            <p>
              {t("timer.time_out_1")}
              <br />
              <br />
              {t("timer.time_out_2")}
              <strong>{getTimer()}</strong>
            </p>
          </Content>
        }
        bottomContent={
          <Button
            fullWidth
            onClick={() => {
              setshowModal(false);
              setModalClosed(true);
            }}
          >
            {t("timer.got_it")}
          </Button>
        }
      />
    </>
  );
};

export default Timer;
