export const IMAGES_BASE_PATH = "https://m.media-amazon.com";

export const IMAGES = {
  OOPS: `${IMAGES_BASE_PATH}/images/G/01/CosmosWeb/VirtualCallboxGuest/location-unknown.png`,
  AP_PLACEHOLDER: `${IMAGES_BASE_PATH}/images/G/01/CosmosWeb/VirtualCallboxGuest/placeholder.png`,
  OOPS_404: `${IMAGES_BASE_PATH}/images/G/01/CosmosWeb/VirtualCallboxGuest/oops-404.png`,
  ABOUT_STEP_1: `${IMAGES_BASE_PATH}/images/G/01/CosmosWeb/VirtualCallboxGuest/step-1.png`,
  ABOUT_STEP_2: `${IMAGES_BASE_PATH}/images/G/01/CosmosWeb/VirtualCallboxGuest/step-2.png`,
  ABOUT_STEP_3: `${IMAGES_BASE_PATH}/images/G/01/CosmosWeb/VirtualCallboxGuest/step-3.png`,
  ABOUT_IPHONE_CONTROL: `${IMAGES_BASE_PATH}/images/G/01/CosmosWeb/VirtualCallboxGuest/iphone-control-center-nfc-tag-reader_1.png`,
  ABOUT_ANDROID_CONTROL: `${IMAGES_BASE_PATH}/images/G/01/CosmosWeb/VirtualCallboxGuest/enable-NFC-from-Android-OS-settings_1.png`,
};
