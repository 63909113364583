import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Header } from "../../components";
import IconOffline from "../../components/Icons/IconOffline";
import { TestId } from "../../utils";
import { ThingsToCheck } from "./CallDroppedError.styles";

const CallDroppedError: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <ErrorMessage
        title={t("call_dropped_error.title")}
        description={t("call_dropped_error.subtitle")}
        testId={TestId.CallDroppedError}
        hideSurveyLink={true}
      >
        <IconOffline></IconOffline>
      </ErrorMessage>
      <ThingsToCheck>
        <h3>{t("things_to_check")}</h3>
        <ul>
          <li>{t("call_dropped_error.thing_to_check_1")}</li>
        </ul>
      </ThingsToCheck>
    </>
  );
};

export default CallDroppedError;
