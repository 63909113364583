import { HTMLAttributes, useEffect } from "react";
import {
  StyledModal,
  ModalHeader,
  ModalContent,
  ModalBottom,
  HeaderText,
  LanguageWidgetContainer,
} from "./Modal.styles";
import { LanguageWidget } from "..";

export type ModalProps = {
  isOpen?: boolean;
  bottomContent?: React.ReactNode;
  header?: React.ReactNode;
  content: React.ReactNode;
  size?: "sm" | "lg";
} & HTMLAttributes<HTMLDivElement>;

const Modal = ({
  isOpen = true,
  header,
  content,
  bottomContent,
  size = "lg",
  ...props
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <StyledModal size={size} role="dialog" aria-label="dialog" {...props}>
          {header && (
            <ModalHeader>
              <HeaderText>{header}</HeaderText>
              <LanguageWidgetContainer>
                <LanguageWidget />
              </LanguageWidgetContainer>
            </ModalHeader>
          )}
          <ModalContent size={size}>{content}</ModalContent>
          {bottomContent && <ModalBottom>{bottomContent}</ModalBottom>}
        </StyledModal>
      )}
    </>
  );
};

export default Modal;
