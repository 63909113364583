import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { I18N_KEY, environment, metricEvents } from "../utils";
import en from "../i18n/translations/en/translation.json";
import es from "../i18n/translations/es/translation.json";
import fr from "../i18n/translations/fr/translation.json";
import frCA from "../i18n/translations/frCA/translation.json";
import de from "../i18n/translations/de/translation.json";
import it from "../i18n/translations/it/translation.json";
import ja from "../i18n/translations/ja/translation.json";
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../contexts";
import { useHeap } from "./useHeap";

export enum Languages {
  en = "en",
  es = "es",
  de = "de",
  fr = "fr",
  frCA = "fr-CA",
  it = "it",
  ja = "ja",
}

export const supportedLngs = Object.values(Languages);

const useI18n = () => {
  const { setNeedsFallback } = useContext(I18nContext);
  const [i18nReady, setI18nReady] = useState(false);
  const { track } = useHeap();

  useEffect(() => {
    i18n.use(initReactI18next).init(
      {
        fallbackLng: Languages.en,
        supportedLngs,
        debug: false,
        interpolation: {
          escapeValue: false,
        },
        resources: {
          [Languages.en]: en,
          [Languages.es]: es,
          [Languages.fr]: fr,
          [Languages.frCA]: frCA,
          [Languages.de]: de,
          [Languages.it]: it,
          [Languages.ja]: ja,
        },
      },
      () => {
        const storedLocale = localStorage.getItem(I18N_KEY);

        if (storedLocale) {
          // First try to use language stored in local storage
          i18n.changeLanguage(storedLocale).then(() => {
            track(metricEvents.initialLanguage, {
              language: i18n.resolvedLanguage,
              source: "local_storage",
            });
          });
        } else {
          // next try using browser language
          const browserLanguage = environment.browserLanguage?.split("-")[0];
          if (supportedLngs.includes(browserLanguage as Languages)) {
            i18n.changeLanguage(environment.browserLanguage).then(() => {
              track(metricEvents.initialLanguage, {
                language: i18n.resolvedLanguage,
                source: "browser_settings",
              });
            });
          } else {
            // fallback from VCGW is needed
            setNeedsFallback(true);
          }
        }

        setI18nReady(true);
      }
    );
  }, [setNeedsFallback, track]);

  return { i18nReady };
};

export default useI18n;
