import { SVGProps } from "react";
import { PRIMARY_500 } from "../../styles/colors";

const IconDisabledMicrophone = ({
  fill = PRIMARY_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.09995 9.9V3.5C6.09995 2.1 7.19995 1 8.59995 1C9.99995 1 11.1 2.1 11.1 3.5V4.9L12.1 3.9V3.5C12.1 1.6 10.5 0 8.59995 0C6.69995 0 5.09995 1.6 5.09995 3.5V10.9L6.09995 9.9Z"
        fill={fill}
      />
      <path
        d="M3.29995 12.6C3.09995 12.1 2.99995 11.5 2.99995 11V7.5C3.09995 7.2 2.79995 7 2.59995 7C2.39995 7 2.09995 7.2 2.09995 7.5V11C2.09995 11.9 2.29995 12.7 2.59995 13.4L3.29995 12.6Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3 2.1L1.09995 16.2C0.899951 16.4 0.899951 16.7 1.09995 16.9C1.29995 17.1 1.59995 17.1 1.79995 16.9L3.59995 15.1C4.69995 16.4 6.29995 17.3 8.09995 17.4V19H4.59995C4.29995 19 4.09995 19.2 4.09995 19.5C4.09995 19.8 4.29995 20 4.59995 20H12.6C12.9 20 13.1 19.8 13.1 19.5C13.1 19.2 12.9 19 12.6 19H9.09995V17.5C12.5 17.2 15.1 14.4 15.1 11V7.5C15.1 7.2 14.9 7 14.6 7C14.3 7 14.1 7.2 14.1 7.5V11C14.1 14 11.6 16.5 8.59995 16.5C6.89995 16.5 5.29995 15.7 4.29995 14.5L5.99995 12.8C6.59995 13.5 7.59995 14 8.59995 14C10.5 14 12.1 12.4 12.1 10.5V6.7L16 2.8C16.2 2.6 16.2 2.3 16 2.1C15.8 1.9 15.5 1.9 15.3 2.1ZM8.59995 13C9.99995 13 11.1 11.9 11.1 10.5V7.7L6.69995 12.1C7.19995 12.6 7.79995 13 8.59995 13Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconDisabledMicrophone;
