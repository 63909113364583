import { HTMLAttributes } from "react";
import { StyledButton } from "./Button.styles";

type ButtonProps = {
  fullWidth?: boolean;
  disabled?: boolean;
  noBorders?: boolean;
  variant?: "primary" | "secondary" | "tertiary";
} & HTMLAttributes<HTMLButtonElement>;

const Button = ({
  fullWidth = false,
  disabled = false,
  noBorders = false,
  variant = "primary",
  children,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      variant={variant}
      fullWidth={fullWidth}
      disabled={disabled}
      noBorders={noBorders}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
