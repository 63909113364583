import { SVGProps } from "react";
import { PRIMARY_500 } from "../../styles/colors";

const IconLoading = ({
  fill = PRIMARY_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM16 28.8C23.0692 28.8 28.8 23.0692 28.8 16C28.8 8.93076 23.0692 3.2 16 3.2C8.93076 3.2 3.2 8.93076 3.2 16C3.2 23.0692 8.93076 28.8 16 28.8Z"
        fill="#F6F8FA"
      />
      <path
        d="M16 32C24.8366 32 32 24.8366 32 16C32 11.6974 30.3017 7.7915 27.5389 4.91602L25.2755 7.17933C27.4596 9.47524 28.8 12.5811 28.8 16C28.8 23.0693 23.0692 28.8 16 28.8L16 32Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconLoading;
