import { useEffect, useState } from "react";

export type PermissionsState = {
  microphone: PermissionState | null;
  camera: PermissionState | null;
};

const usePermissions = () => {
  const [microphone, setMicrophone] = useState<PermissionState | null>(null);
  const [camera, setCamera] = useState<PermissionState | null>(null);

  useEffect(() => {
    navigator.permissions
      ?.query({ name: "microphone" as PermissionName })
      .then((permission) => {
        setMicrophone(permission.state);
        permission.onchange = () => {
          setMicrophone(permission.state);
        };
      });

    navigator.permissions
      ?.query({ name: "camera" as PermissionName })
      .then((permission) => {
        setCamera(permission.state);
        permission.onchange = () => {
          setCamera(permission.state);
        };
      });

    // For iOS 15
    if (
      !navigator.permissions &&
      navigator.mediaDevices &&
      !!navigator.mediaDevices.enumerateDevices
    ) {
      navigator.mediaDevices.enumerateDevices().then((stream) => {
        const mic = stream.some(
          (dev) => dev.kind === "audioinput" && dev.deviceId.length > 0
        );
        const cam = stream.some(
          (dev) => dev.kind === "videoinput" && dev.deviceId.length > 0
        );
        mic && setMicrophone("granted");
        cam && setCamera("granted");
      });
    }
  }, []);

  return { microphone, camera } as PermissionsState;
};

export default usePermissions;
