/**
 * Official Color System
 * @see https://www.figma.com/file/O0FWE4TJWmIGCksbLUEL0i0y/Web-(Logged-In)?node-id=0%3A1138
 */

const BLACK = "#000";
const BLACK_65 = "rgba(0, 0, 0, 0.65)";
const WHITE = "#fff";

const PRIMARY_800 = "#ADDAED";
const PRIMARY_700 = "#10628A";
const PRIMARY_600 = "#1476AD";
const PRIMARY_500 = "#1998d5";
const PRIMARY_400 = "#1476AD";
const PRIMARY_200 = "#AFDBF1";
const PRIMARY_100 = "#EFF8FC";

const SECONDARY_700 = "#9C4308";
const SECONDARY_500 = "#F1670D";
const SECONDARY_400 = "#C74E00";
const SECONDARY_200 = "#FACAAB";
const SECONDARY_100 = "#FEF4EE";

const TERTIARY_700 = "#81919F";
const TERTIARY_500 = "#DEE5EC";
const TERTIARY_300 = "#F6F8FA";

const NEUTRAL_700 = "#0b0b0b";
const NEUTRAL_600 = "#282B2F";
const NEUTRAL_500 = "#424242";
const NEUTRAL_300 = "#6E6E6E";
const NEUTRAL_200 = "#BDBDBD";
const NEUTRAL_100 = "#ECECEC";
const NEUTRAL_50 = "#FAFBFC";

const NEGATIVE_700 = "#7C1212";
const NEGATIVE_500 = "#D4231A";
const NEGATIVE_400 = "#A72721";
const NEGATIVE_200 = "#F0B2AF";
const NEGATIVE_100 = "#FCEFEF";

const LIGHT_NEGATIVE_BASE = "#D9342A";

const POSITIVE_700 = "#22580E";
const POSITIVE_500 = "#178019";
const POSITIVE_400 = "#157217";
const POSITIVE_200 = "#AED3AF";
const POSITIVE_100 = "#EFF6EF";

const AMAZON_700 = "#A66300"; // Pressed state
const AMAZON_500 = "#FF9900"; // Main use
const AMAZON_200 = "#FFDBA6"; // Disabled state

const CAUTION_500 = "#FDD835";
const CAUTION_700 = "#A48C22";

// Named Colors
const NATIVE_GREY = "#808080";
const GREY = "#bdbdbd";
const GREY_LIGHT = "#dadada";

const SNOW = "#fafafa";
const GREY_78 = "#c7c7c7";
const FORD_GREY = "#979797";
const GREY_53 = "#878787";

const BACKGROUND = NEUTRAL_300;
const BACKGROUND_2 = NEUTRAL_50;

const MUTED = "#cccccc";

const SURFACE = WHITE;
const ON_SURFACE = NEUTRAL_500;

const LIVE = NEGATIVE_500;

const CRITICAL = NEGATIVE_500;

const DEFAULT_BORDER_COLOR = TERTIARY_500;
const HOVER_BORDER_COLOR = PRIMARY_500;
const SELECTED_BORDER_COLOR = HOVER_BORDER_COLOR;

const NEXT_DOOR_GREEN = "#40A142";
const FACEBOOK_BLUE = "#006494";
const TWITTER_PRIMARY = "#4DC2FA";

const LIGHT_CONTENT = "rgba(66, 66, 66, .65)";

export {
  BLACK,
  BLACK_65,
  WHITE,
  NATIVE_GREY,
  GREY,
  GREY_LIGHT,
  SNOW,
  GREY_78,
  FORD_GREY,
  GREY_53,
  PRIMARY_800,
  PRIMARY_700,
  PRIMARY_600,
  PRIMARY_500,
  PRIMARY_400,
  PRIMARY_200,
  PRIMARY_100,
  SECONDARY_700,
  SECONDARY_500,
  SECONDARY_400,
  SECONDARY_200,
  SECONDARY_100,
  TERTIARY_700,
  TERTIARY_500,
  TERTIARY_300,
  NEUTRAL_700,
  NEUTRAL_500,
  NEUTRAL_300,
  NEUTRAL_200,
  NEUTRAL_100,
  NEUTRAL_50,
  NEUTRAL_600,
  NEXT_DOOR_GREEN,
  NEGATIVE_700,
  NEGATIVE_500,
  NEGATIVE_400,
  NEGATIVE_200,
  NEGATIVE_100,
  LIGHT_NEGATIVE_BASE,
  POSITIVE_700,
  POSITIVE_500,
  POSITIVE_400,
  POSITIVE_200,
  POSITIVE_100,
  AMAZON_700,
  AMAZON_500,
  AMAZON_200,
  CAUTION_500,
  CAUTION_700,
  BACKGROUND,
  BACKGROUND_2,
  MUTED,
  SURFACE,
  ON_SURFACE,
  LIVE,
  CRITICAL,
  DEFAULT_BORDER_COLOR,
  SELECTED_BORDER_COLOR,
  HOVER_BORDER_COLOR,
  FACEBOOK_BLUE,
  TWITTER_PRIMARY,
  LIGHT_CONTENT,
};
