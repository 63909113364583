import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }

  .no-scroll {
    overflow: hidden;
    position: relative;
  }
`;

export default GlobalStyle;
