import { API } from "../utils/constants";
import { axiosInstance } from "./axiosInstance";

export type Resident = {
  name: string;
  unit: string | null;
  id: string;
};

export type PropertyAddress = {
  addressLine1?: string;
  city?: string;
  stateOrRegion?: string;
  countryCode?: string;
  postalCode?: string;
};

export type ResidentsListResponse = {
  propertyName: string;
  propertyAddress?: PropertyAddress;
  propertyId: string;
  accessPointId: string;
  residents: Resident[];
};

export const getResidentsList = async (
  sessionToken: string,
  setTokenExpiration: (expiration: number) => void
) => {
  return axiosInstance
    .get<ResidentsListResponse>(API.RESIDENTS_LIST, {
      headers: { [API.SESSION_TOKEN_HEADER]: sessionToken },
    })
    .then((response) => {
      const { data, headers } = response;

      const expiration = headers[API.SESSION_EXPIRATION_HEADER];
      if (expiration) {
        setTokenExpiration(Number(expiration));
      } else {
        // Header is exposed now. Setting to 0 so we see error if it's ever not exposed again.
        setTokenExpiration(Number("0"));
      }
      return data;
    });
};
