import { useLayoutEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import { StyledApp } from "./App.styles";
import {
  About,
  Directory,
  LiveView,
  ValidationError,
  SessionError,
  CallDroppedError,
  OngoingCallError,
  DisabledService,
  ResidentUnavailable,
  ActiveNFCError,
} from "./pages";
import { ROUTES } from "./utils/routes";
import { useI18n, useThirdPartyAnalytics } from "./hooks";
import { NfcGuard } from "./components";
import { I18nProvider, SessionTokenProvider, SocketProvider } from "./contexts";
import GlobalStyle from "./styles/globalStyles";
import { ErrorBoundary } from "react-error-boundary";
import { logger } from "./utils";

function logToService(error: any, info: any) {
  logger.error("Error boundary:", error, info);
}

function App() {
  useLayoutEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    appHeight();

    window.addEventListener("resize", appHeight);
    return () => {
      window.removeEventListener("resize", appHeight);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SessionTokenProvider>
        <SocketProvider>
          <I18nProvider>
            <AppWrapper />
          </I18nProvider>
        </SocketProvider>
      </SessionTokenProvider>
    </ThemeProvider>
  );
}

const AppWrapper = () => {
  const { i18nReady } = useI18n();
  const location = useLocation();
  useThirdPartyAnalytics();

  return i18nReady ? (
    <StyledApp>
      <ErrorBoundary FallbackComponent={ValidationError} onError={logToService}>
        <Routes>
          <Route path={ROUTES.ABOUT} element={<About />}></Route>
          <Route
            path={ROUTES.DISABLED_SERVICE}
            element={<DisabledService />}
          ></Route>
          <Route
            path={ROUTES.VALIDATION_ERROR}
            element={<ValidationError />}
          ></Route>
          <Route path={ROUTES.SESSION_ERROR} element={<SessionError />}></Route>
          <Route
            path={ROUTES.CALL_DROPPED_ERROR}
            element={<CallDroppedError />}
          ></Route>
          <Route
            path={ROUTES.ONGOING_CALL_ERROR}
            element={<OngoingCallError />}
          ></Route>
          <Route
            path={ROUTES.RESIDENT_UNAVAILABLE}
            element={<ResidentUnavailable />}
          ></Route>
          <Route
            path={ROUTES.TAMPER_ERROR}
            element={<ActiveNFCError />}
          ></Route>
          <Route
            path={ROUTES.TIMESTAMP_ERROR}
            element={<SessionError isTimestampError={true} />}
          ></Route>
          <Route element={<NfcGuard />}>
            <Route path={ROUTES.DIRECTORY} element={<Directory />}></Route>
            <Route path={ROUTES.LIVE} element={<LiveView />}></Route>
            <Route
              path=""
              element={
                <Navigate
                  to={{
                    pathname: ROUTES.DIRECTORY,
                    search: location.search,
                  }}
                />
              }
            />
          </Route>
        </Routes>
      </ErrorBoundary>
    </StyledApp>
  ) : (
    <></>
  );
};

export default App;
