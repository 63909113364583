import { SVGProps } from "react";
import { NEUTRAL_500 } from "../../styles/colors";

const IconClose = ({
  fill = NEUTRAL_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.15417 1.15414C0.831943 1.47637 0.831943 1.99934 1.15417 2.32157L6.83257 7.99997L1.15417 13.6784C0.831943 14.0006 0.831943 14.5236 1.15417 14.8458C1.4764 15.168 1.99937 15.168 2.3216 14.8458L8 9.1674L13.6784 14.8458C14.0006 15.168 14.5236 15.168 14.8458 14.8458C15.1681 14.5236 15.1681 14.0006 14.8458 13.6784L9.16743 7.99997L14.8458 2.32157C15.1681 1.99934 15.1681 1.47637 14.8458 1.15414C14.5236 0.831913 14.0006 0.831913 13.6784 1.15414L8 6.83254L2.3216 1.15414C1.99937 0.831912 1.4764 0.831913 1.15417 1.15414Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.175"
      />
    </svg>
  );
};
export default IconClose;
