import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Header, IconCallBusy } from "../../components";
import { useHeap } from "../../hooks";
import { metricEvents } from "../../utils";

const ActiveNFCError: FC = () => {
  const { t } = useTranslation();
  const { track } = useHeap();

  useEffect(() => {
    track(metricEvents.tamperError);
  }, [track]);

  return (
    <>
      <Header />
      <ErrorMessage
        title={t("directory_unavailable.title")}
        description={t("directory_unavailable.detail")}
      >
        <IconCallBusy />
      </ErrorMessage>
    </>
  );
};

export default ActiveNFCError;
