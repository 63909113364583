import styled from "styled-components";

export const PrivacyNotice = styled.div`
  background: ${({ theme }) => theme.white};
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;

  > h3 {
    margin-top: 12px;
    margin-bottom: 8px;
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
  }

  > p {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Header = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.tertiary500};
  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  margin: 0px;
  border: none;
  background: transparent;
  height: 56px;
  padding: 0px 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const BottomContent = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.primary700};
`;
