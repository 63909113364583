import { FC } from "react";
import { StyledUserBox } from "./UserBox.styles";

export interface UserBoxProps {
  initials: string;
  isSmall?: boolean;
}

const UserBox: FC<UserBoxProps> = ({ initials, isSmall }) => {
  return (
    <StyledUserBox isSmall={isSmall}>
      <span>{initials}</span>
    </StyledUserBox>
  );
};

export default UserBox;
