import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { hashSessionId } from "../utils/hashToken";

export const SessionTokenContext = createContext<{
  sessionTokenWrapper: [string, (token: string) => void];
  tokenExpirationWrapper: [number | undefined, (expiry: number) => void];
  encryptedSessionToken: string;
}>({
  sessionTokenWrapper: ["", (token: string) => {}],
  tokenExpirationWrapper: [0, (expiration: number) => {}],
  encryptedSessionToken: "",
});

export const SessionTokenProvider = ({ children }: PropsWithChildren<{}>) => {
  const [sessionToken, setSessionToken] = useState("");
  const [tokenExpiration, setTokenExpiration] = useState<number | undefined>();
  const [encryptedSessionToken, setEncryptedSessionToken] = useState("");

  useEffect(() => {
    const setHashedSession = async () => {
      const hashedSession = await hashSessionId(sessionToken);
      if (hashedSession) {
        setEncryptedSessionToken(hashedSession);
      }
    };

    if (sessionToken) {
      setHashedSession();
    }
  }, [sessionToken]);

  return (
    <SessionTokenContext.Provider
      value={{
        sessionTokenWrapper: [sessionToken, setSessionToken],
        tokenExpirationWrapper: [tokenExpiration, setTokenExpiration],
        encryptedSessionToken: encryptedSessionToken,
      }}
    >
      {children}
    </SessionTokenContext.Provider>
  );
};
