import styled, { css } from "styled-components";

export const StyledModal = styled.div<{ size: "sm" | "lg" }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--app-height);
  background: ${({ theme }) => theme.white};
  overflow: hidden;
  z-index: 10;
  display: flex;
  flex-direction: column;

  ${({ size }) =>
    size === "sm" &&
    css`
      background: rgba(0, 0, 0, 0.65);
      justify-content: center;
      align-items: center;
    `}
`;

export const ModalContent = styled.div<{ size: "sm" | "lg" }>`
  ${({ size }) =>
    size === "lg" &&
    css`
      padding: 24px;
      flex-grow: 1;
    `}

  ${({ size }) =>
    size === "sm" &&
    css`
      padding: 16px 8px;
      background: ${({ theme }) => theme.white};
      border-radius: 4px;
      margin: 16px;
      width: calc(100% - 48px);
    `}
`;

export const ModalHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.tertiary500};
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
`;

export const HeaderText = styled.div`
  grid-column: 2;
`;

export const LanguageWidgetContainer = styled.div`
  grid-column: 3;
  justify-self: end;
`;

export const ModalBottom = styled.div`
  padding: 16px 24px;

  button {
    margin-top: 12px;
  }
`;
