import { SVGProps } from "react";
import { NEUTRAL_500 } from "../../styles/colors";

const IconSelectArrow = ({
  fill = NEUTRAL_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33325 6.66666H11.6666L7.99992 10L4.33325 6.66666Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconSelectArrow;
