import { Trans, useTranslation } from "react-i18next";
import {
  Contact,
  SearchBox,
  PermissionAlert,
  LocationDetails,
  Button,
  Modal,
  BrowserNotSupportedModal,
} from "../";
import { Resident, DirectoryResponse } from "../../api";
import {
  Search,
  ResidentDirectoryText,
  NoResult,
  TermsOfService,
  NoResidents,
  ModalTitle,
  ModalBtns,
} from "./ResidentDirectory.styles";
import {
  TestId,
  surveyUrl,
  ringPrivacyUrl,
  ringTermsUrl,
  isSupportedBrowser,
  environment,
} from "../../utils";
import { useContext, useState } from "react";
import { Link } from "../../App.styles";
import { I18nContext } from "../../contexts";

export type ResidentDirectoryProps = {
  data: DirectoryResponse;
  showAlert: boolean;
  handleResidentCall: (val: Resident, status: boolean) => void;
  callFrontDesk: () => void;
};

const ResidentDirectory = ({
  data,
  showAlert,
  handleResidentCall,
}: ResidentDirectoryProps) => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState(data.people);
  const [showModal, setShowModal] = useState(false);
  const [showBrowserModal, setShowBrowserModal] = useState(false);
  const [selected, setSelected] = useState<Resident>();
  const { propertyCountry } = useContext(I18nContext);

  const handleFilter = (val: string) => {
    if (val && val.length) {
      const filtered = data?.people.filter(
        (item) =>
          item.name?.toLowerCase().includes(val.toLocaleLowerCase()) ||
          item.unit?.toLocaleLowerCase().includes(val.toLocaleLowerCase())
      );
      setContacts(filtered);
    } else {
      setContacts(data?.people);
    }
  };

  const handleModalStatus = (status: boolean) => {
    if (selected) {
      handleResidentCall(selected, status);
    }
    setShowModal(false);
    setSelected(undefined);
  };

  const isEU = () => {
    const EU = ["GB", "DE", "ES", "IT", "FR"];

    return EU.includes(propertyCountry.toUpperCase());
  };

  return (
    <>
      <LocationDetails
        propertyName={data.addressName}
        propertyAddress={data.address}
      />

      <Search>
        {showAlert && <PermissionAlert />}
        <SearchBox
          placeholder={t("directory.search") as string}
          onChange={(e) => handleFilter(e.currentTarget.value)}
        />
        <TermsOfService>
          <Trans
            i18nKey={
              isEU()
                ? "directory.terms_of_service_eu"
                : "directory.terms_of_service"
            }
          >
            By using this service, you agree to the
            <a target="_blank" rel="noreferrer" href={ringTermsUrl}>
              Ring Terms of Service
            </a>
            and
            <a target="_blank" rel="noreferrer" href={ringPrivacyUrl}>
              Privacy Notice
            </a>
            .
          </Trans>
        </TermsOfService>
      </Search>
      <div>
        <ResidentDirectoryText>
          <span>{t("directory.resident_directory")}</span>
          {environment.displayFeedback && (
            <Link href={surveyUrl} target="_blank">
              {t("survey_link_display")}
            </Link>
          )}
        </ResidentDirectoryText>
        {data.people.length ? (
          <>
            {!contacts?.length && (
              <NoResult data-testid={TestId.NoResults}>
                {t("directory.no_results")}
              </NoResult>
            )}

            {contacts?.map((item, idx) => (
              <Contact
                resident={item}
                key={idx}
                onClick={() => {
                  setSelected(item);
                  if (isSupportedBrowser()) {
                    setShowModal(true);
                  } else {
                    setShowBrowserModal(true);
                  }
                }}
                isDisabled={showAlert}
              />
            ))}
          </>
        ) : (
          <NoResidents>{t("directory.empty_list")}</NoResidents>
        )}
      </div>

      <Modal
        size="sm"
        isOpen={showModal && selected !== undefined}
        content={
          <>
            <ModalTitle>
              {t("call_contact", {
                name: selected?.name,
              })}
            </ModalTitle>
            <ModalBtns>
              <Button fullWidth onClick={() => handleModalStatus(true)}>
                {t("call")}
              </Button>
              <Button
                variant="secondary"
                noBorders
                fullWidth
                onClick={() => {
                  handleModalStatus(false);
                }}
              >
                {t("cancel")}
              </Button>
            </ModalBtns>
          </>
        }
      ></Modal>

      {showBrowserModal && (
        <BrowserNotSupportedModal onClose={() => setShowBrowserModal(false)} />
      )}
    </>
  );
};

export default ResidentDirectory;
