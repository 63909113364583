import styled, { css } from "styled-components";
import { Equip } from "./styles/font";

export const StyledApp = styled.div`
  ${Equip}
  font-family: Equip,Helvetica,Arial,sans-serif !important;
  color: ${({ theme }) => theme.neutral500};
  background: ${({ theme }) => theme.white};
  height: 100%;
`;

export const Link = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-top: 12px;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.primary};
  text-decoration: underline;
`;

export const ModalContent = styled.div`
  > h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 8px;
    margin-top: 0px;
  }
  > p {
    margin: 0px;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const StyledBox = styled.div<{ constrained?: boolean }>`
  background: ${({ theme }) => theme.primary100};
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 16px 24px;
  border-radius: 4px;
  display: flex;

  ${({ constrained }) =>
    constrained &&
    css`
      max-width: 328px;
    `}
  > svg {
    flex-shrink: 0;
  }
`;
