import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { Languages } from "../hooks/useI18n";

export const I18nContext = createContext<{
  needsFallback: boolean;
  setNeedsFallback: (needsFallback: boolean) => void;
  locationLanguage: string;
  setLocationLanguage: (locationLanguage: string) => void;
  propertyCountry: string;
  selectedLng?: Languages;
  setSelectedLng: (selectedLanguage: Languages) => void;
}>({
  needsFallback: false,
  setNeedsFallback: (needsFallback: boolean) => {},
  locationLanguage: "",
  setLocationLanguage: (locationLanguage: string) => {},
  propertyCountry: "",
  selectedLng: undefined,
  setSelectedLng: (selectedLanguage: Languages) => {},
});

export const I18nProvider = ({ children }: PropsWithChildren<{}>) => {
  const [needsFallback, setNeedsFallback] = useState<boolean>(false);
  const [locationLanguage, setLocationLanguage] = useState<string>("");
  const [propertyCountry, setPropertyCountry] = useState<string>("");
  const [selectedLng, setSelectedLng] = useState<Languages>();

  useEffect(() => {
    if (locationLanguage) {
      const locationSplits = locationLanguage.split("-");
      // locationLanguage from VCGW is exptected to be as xx-XX
      if (locationSplits.length === 2) {
        setPropertyCountry(locationSplits[1]);
      } else {
        setPropertyCountry("US");
      }
    }
  }, [locationLanguage]);

  return (
    <I18nContext.Provider
      value={{
        needsFallback,
        setNeedsFallback,
        locationLanguage,
        setLocationLanguage,
        propertyCountry,
        selectedLng,
        setSelectedLng,
      }}
    >
      {children}
    </I18nContext.Provider>
  );
};
