import styled, { css } from "styled-components";

export const StyledAccordion = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.tertiary500};
  -webkit-transition: height 0.5s ease;
  transition: height 0.5s ease;
`;

export const Header = styled.div<{ isOpen: boolean }>`
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  > p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
    margin-right: 12px;
  }
  > svg {
    flex-shrink: 0;
    -webkit-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      > svg {
        transform: rotate(180deg);
      }
    `}
`;

export const Content = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  max-height: 0px;
  -webkit-transition: max-height 0.5s ease;
  transition: max-height 0.5s ease;

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 950px;
    `}
`;
