import { FC, useEffect, useRef } from "react";
import { Resident } from "../../api";
import {
  CallingBox,
  ConnectedText,
  ResidentMedia,
  UserName,
} from "./CallConnected.styles";
import { getInitials } from "../../utils/stringUtils";
import GuestMedia from "../GuestMedia";
import { ControlState, VideoConstraints } from "../../pages/LiveView/LiveView";
import UserBox from "../UserBox";
import { useTranslation } from "react-i18next";

interface CallConnectedProps {
  contact: Resident;
  audioStream: MediaStream | null;
  constraints: VideoConstraints;
  guestMedia?: MediaStream;
  cameraControl?: ControlState;
}

const CallConnected: FC<CallConnectedProps> = ({
  contact,
  audioStream,
  constraints,
  guestMedia,
  cameraControl,
}) => {
  const audioElement = useRef<HTMLAudioElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (audioElement.current) {
      audioElement.current.srcObject = audioStream;
      audioElement.current.play();
    }
  }, [audioStream]);

  return (
    <CallingBox>
      <ResidentMedia>
        <audio ref={audioElement} />
        <UserBox initials={getInitials(contact.name)}></UserBox>
        <UserName>{contact.name}</UserName>
        <ConnectedText>{t("live_view.connected")}</ConnectedText>
      </ResidentMedia>

      <GuestMedia
        videoConstraints={constraints}
        guestMedia={guestMedia}
        state={cameraControl}
        thumbnail
      />
    </CallingBox>
  );
};

export default CallConnected;
