import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomContent = styled.div`
  padding: 16px 24px;
  display: flex;
  position: fixed;
  bottom: 0px;
  width: calc(100% - 48px);
  left: 0px;
`;

export const Content = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;

  > h2 {
    margin: 8px 0px;
    font-weight: 500;
  }

  > p {
    font-weight: 300;
    line-height: 24px;
    margin: 0px;
  }
`;
