import { SVGProps } from "react";
import { PRIMARY_500 } from "../../styles/colors";

const IconGeo = ({ fill = PRIMARY_500, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 40C8.78 40 0 36.26 0 31.5C0 28.3 3.84 25.5 10.28 24.02C10.5475 23.9575 10.8287 23.9992 11.0665 24.1366C11.3043 24.2741 11.4807 24.497 11.56 24.76C11.614 25.0248 11.5628 25.3003 11.4172 25.528C11.2716 25.7558 11.0431 25.9179 10.78 25.98C5.5 27.18 2.1 29.34 2.1 31.5C2.1 34.58 9.46 38 20.1 38C30.74 38 38.1 34.58 38.1 31.5C38.1 29.34 34.7 27.18 29.42 25.98C29.1569 25.9179 28.9284 25.7558 28.7828 25.528C28.6372 25.3003 28.586 25.0248 28.64 24.76C28.7149 24.4942 28.8906 24.2682 29.1297 24.13C29.3687 23.9918 29.6523 23.9523 29.92 24.02C36.36 25.5 40.2 28.3 40.2 31.5C40 36.26 31.22 40 20 40Z"
        fill={fill}
      />
      <path
        d="M20 14C21.6569 14 23 12.6569 23 11C23 9.34315 21.6569 8 20 8C18.3431 8 17 9.34315 17 11C17 12.6569 18.3431 14 20 14Z"
        fill={fill}
      />
      <path
        d="M20 2C22.3853 2.00528 24.6714 2.95519 26.3581 4.64187C28.0448 6.32856 28.9947 8.61468 29 11C29 14.46 24 23.12 20 29.12C16 23.12 11 14.46 11 11C11.0053 8.61468 11.9552 6.32856 13.6419 4.64187C15.3286 2.95519 17.6147 2.00528 20 2ZM20 0C17.0826 0 14.2847 1.15893 12.2218 3.22183C10.1589 5.28473 9 8.08262 9 11C9 17 20 32.66 20 32.66C20 32.66 31 17.08 31 11C31 8.08262 29.8411 5.28473 27.7782 3.22183C25.7153 1.15893 22.9174 0 20 0Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconGeo;
