import { SVGProps } from "react";
import { NEUTRAL_500 } from "../../styles/colors";

const IconMicrophone = ({
  fill = NEUTRAL_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6113 6.99994C12.3353 6.99994 12.1113 7.22394 12.1113 7.49994V10.9999C12.1113 14.0379 9.64933 16.4999 6.61133 16.4999C3.57333 16.4999 1.11133 14.0379 1.11133 10.9999V7.49994C1.11133 7.22394 0.887328 6.99994 0.611328 6.99994C0.335328 6.99994 0.111328 7.22394 0.111328 7.49994V10.9999C0.117328 14.4089 2.74733 17.2029 6.08833 17.4689L6.11133 18.9999H2.61133C2.33533 18.9999 2.11133 19.2239 2.11133 19.4999C2.11133 19.7759 2.33533 19.9999 2.61133 19.9999H10.6113C10.8873 19.9999 11.1113 19.7759 11.1113 19.4999C11.1113 19.2239 10.8873 18.9999 10.6113 18.9999H7.11133V17.4699C10.4753 17.2029 13.1053 14.4089 13.1113 11.0009V7.49994C13.1113 7.22394 12.8873 6.99994 12.6113 6.99994ZM6.61133 0.999939C7.99233 0.999939 9.11133 2.11894 9.11133 3.49994V10.4999C9.11133 11.8809 7.99233 12.9999 6.61133 12.9999C5.23033 12.9999 4.11133 11.8809 4.11133 10.4999V3.49994C4.11133 2.11894 5.23033 0.999939 6.61133 0.999939ZM6.61133 -6.10352e-05C4.67833 -6.10352e-05 3.11133 1.56694 3.11133 3.49994V10.4999C3.11133 12.4329 4.67833 13.9999 6.61133 13.9999C8.54433 13.9999 10.1113 12.4329 10.1113 10.4999V3.49994C10.1113 1.56694 8.54433 -6.10352e-05 6.61133 -6.10352e-05Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconMicrophone;
