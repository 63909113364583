import { SVGProps } from "react";
import { WHITE } from "../../styles/colors";

const IconPhoneDown = ({ fill = WHITE, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="10"
      viewBox="0 0 21 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6111 -6.10352e-05C8.14111 -6.10352e-05 2.79111 0.389939 1.37111 2.99994C0.431106 4.72994 0.371106 8.27994 1.23111 8.78994C1.61612 8.95313 2.03368 9.025 2.45111 8.99994C3.01571 8.98775 3.57804 8.92415 4.13111 8.80994C4.97896 8.65109 5.7713 8.27559 6.43111 7.71994C6.91111 7.05994 7.07111 5.02994 7.67111 4.64994C8.57597 4.17597 9.59077 3.95162 10.6111 3.99994C11.6314 3.95162 12.6462 4.17597 13.5511 4.64994C14.1511 5.02994 14.3111 7.05994 14.7911 7.71994C15.4509 8.27559 16.2433 8.65109 17.0911 8.80994C17.6442 8.92415 18.2065 8.98775 18.7711 8.99994C19.1901 9.01871 19.6077 8.93998 19.9911 8.76994C20.8911 8.25994 20.7911 4.70994 19.8511 2.97994C18.4311 0.389939 13.0811 -6.10352e-05 10.6111 -6.10352e-05Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPhoneDown;
