import styled from "styled-components";
import Card from "../Card";

export const TitleBox = styled.div`
  height: 24px;
  display: flex;
  padding: 8px 12px;

  > svg {
    margin-right: 12px;
  }
`;

export const Title = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const Image = styled.img`
  height: 184px;
  width: 100%;
  object-fit: cover;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
