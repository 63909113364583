import { FC, useContext, useEffect } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../utils";
import { SessionTokenContext } from "../../contexts/SessionTokenContext";

const NfcGuard: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { sessionTokenWrapper } = useContext(SessionTokenContext);
  const [sessionToken, setSessionToken] = sessionTokenWrapper;

  useEffect(() => {
    const token = searchParams.get("session");
    if (!token) {
      navigate(ROUTES.VALIDATION_ERROR);
    } else {
      setSessionToken(token);
    }
  }, [navigate, searchParams, setSessionToken]);

  if (sessionToken) {
    return <Outlet></Outlet>;
  }

  return null;
};

export default NfcGuard;
