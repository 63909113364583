import { SVGProps } from "react";
import { PRIMARY_500 } from "../../styles/colors";

const IconQuestion = ({
  fill = PRIMARY_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.12 24C21.112 23.902 21.108 23.786 21.108 23.67C21.108 22.158 21.858 20.82 23.006 20.008C26.24 17.658 27.02 15.878 27.02 13.698C27 9.878 24.62 7.998 20.48 7.998C18.39 8.028 16.482 8.778 14.984 10.01L15 13.658H15.12C16.312 12.488 17.922 11.742 19.704 11.658C21.72 11.658 23.04 12.458 23.04 14.478C23.04 15.978 22.16 17.158 20.2 18.798C18.93 19.858 18.128 21.442 18.128 23.212C18.128 23.532 18.154 23.846 18.204 24.15L21.12 24.116V24ZM22 29.52C22.006 29.454 22.01 29.378 22.01 29.302C22.01 27.988 20.944 26.922 19.63 26.922C18.316 26.922 17.25 27.988 17.25 29.302C17.25 29.378 17.254 29.454 17.26 29.53C17.252 29.586 17.25 29.662 17.25 29.738C17.25 31.052 18.316 32.118 19.63 32.118C20.944 32.118 22.01 31.052 22.01 29.738C22.01 29.662 22.006 29.586 22 29.51V29.52ZM20 2C29.942 2 38 10.058 38 20C38 29.942 29.942 38 20 38C10.058 38 2 29.942 2 20C2 10.058 10.058 2 20 2ZM20 0C8.954 0 0 8.954 0 20C0 31.046 8.954 40 20 40C31.046 40 40 31.046 40 20C40 8.954 31.046 0 20 0Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconQuestion;
