import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import UpdatingIndicator from "../UpdatingIndicator";
import { TryingToReconnectDiv } from "./TryingToReconnect.styles";

export type TryingToReconnectProps = HTMLAttributes<HTMLDivElement>;

const TryingToReconnect = (props: TryingToReconnectProps) => {
  const { t } = useTranslation();

  return (
    <TryingToReconnectDiv {...props}>
      <UpdatingIndicator updatingText={t("trying_to_reconnect")} />
    </TryingToReconnectDiv>
  );
};

export default TryingToReconnect;
