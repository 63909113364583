import { SVGProps } from "react";
import { WHITE } from "../../styles/colors";

const IconUpdating = ({ fill = WHITE, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.64101 10.9857C3.01232 12.5336 2.66602 14.2263 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333C17.6575 29.3333 19.2449 29.0306 20.7094 28.4775L19.7941 26.6279C18.6082 27.0514 17.3307 27.282 15.9993 27.282C9.76844 27.282 4.7173 22.2309 4.7173 16C4.7173 14.5388 4.99507 13.1425 5.50068 11.861L3.64101 10.9857Z"
        fill="white"
      />
      <path
        d="M26.3838 20.4174C26.9616 19.0607 27.2814 17.5677 27.2814 16C27.2814 9.76905 22.2303 4.71791 15.9993 4.71791C14.3073 4.71791 12.7022 5.09041 11.2617 5.75787L10.2647 3.95941C12.0018 3.1306 13.9464 2.66663 15.9993 2.66663C23.3631 2.66663 29.3327 8.63616 29.3327 16C29.3327 17.9476 28.9151 19.7978 28.1645 21.4657L26.3838 20.4174Z"
        fill="white"
      />
    </svg>
  );
};
export default IconUpdating;
