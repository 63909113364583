import styled from "styled-components";
import { LoadingIndicator } from "../../components";

export const Page = styled.div`
  min-height: 100%;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-top: 12px;
  margin-bottom: 8px;
`;

export const StyledLoadingIndicator = styled(LoadingIndicator)`
  max-height: calc(100vh - 57px);
  padding: 0px;
`;
