import { FC } from "react";
import { IMAGES, TestId, surveyUrl, environment } from "../../utils";
import {
  Page,
  Container,
  Content,
  Title,
  Headline,
  Text,
  Step,
  Bold,
  Item,
  AccordionContent,
  DeviceHeader,
  Devices,
  DeviceImage,
  Support,
  BoxContent,
} from "./About.styles";
import { Accordion, Button, Header, IconQuestion } from "../../components";
import { useTranslation } from "react-i18next";
import { Link, StyledBox } from "../../App.styles";

const About: FC = () => {
  const { t } = useTranslation();
  const { displayAbout } = environment;

  const AboutPage = (
    <Page data-testid={TestId.About}>
      <Header />
      <Container>
        <Content>
          <Title>{t("help.title")}</Title>
          <Headline>{t("help.subhead")}</Headline>

          <Text>{t("help.description")}</Text>

          <Step className="firstStep">
            <Bold sm>{t("help.step_1_title")}</Bold>
            <Text sm>{t("help.step_1_headline")}</Text>
            <Bold sm mt={20}>
              {t("help.step_1_description")}
            </Bold>
            <Link href="#deviceIOS">{t("help.link_ios")}</Link>
            <Link href="#deviceAndroid">{t("help.link_android")}</Link>
          </Step>
          <Step>
            <Bold sm>{t("help.step_2_title")}</Bold>
            <Text sm mb={20}>
              {t("help.step_2_headline")}
            </Text>
            <Text sm>{t("help.step_2_description")}</Text>
          </Step>
          <Step>
            <Bold sm>{t("help.step_3_title")}</Bold>
            <Text sm>{t("help.step_3_headline")}</Text>
          </Step>
          <Step>
            <Bold sm>{t("help.step_4_title")}</Bold>
            <Text sm>{t("help.step_4_headline")}</Text>
          </Step>

          <StyledBox constrained>
            <IconQuestion height={20} width={20}></IconQuestion>
            <BoxContent>
              <Bold sm>{t("help.feedback_title")}</Bold>
              <Button
                fullWidth
                onClick={() => window.open(surveyUrl, "_blank")}
              >
                {t("help.feedback_link")}
              </Button>
            </BoxContent>
          </StyledBox>
        </Content>
        <Devices>
          <DeviceHeader id="deviceIOS">{t("help.IOS_title")}</DeviceHeader>
          <Accordion
            title={t("help.accordion_1")}
            content={
              <AccordionContent>
                <ul>
                  <li>{t("help.accordion_1_item_1")}</li>
                  <li>{t("help.accordion_1_item_2")}</li>
                  <li>{t("help.accordion_1_item_3")}</li>
                  <li>{t("help.accordion_1_item_4")}</li>
                  <li>{t("help.accordion_1_item_5")}</li>
                  <li>{t("help.accordion_1_item_6")}</li>
                </ul>
              </AccordionContent>
            }
          />
          <Accordion
            title={t("help.accordion_2")}
            content={
              <AccordionContent>
                <Text>{t("help.accordion_2_description")}</Text>
                <ul className="margin20">
                  <Item>{t("help.accordion_2_item_1")}</Item>
                  <Item>{t("help.accordion_2_item_2")}</Item>
                  <Item>{t("help.accordion_2_item_3")}</Item>
                </ul>
                <DeviceImage
                  alt="iphone control"
                  src={IMAGES.ABOUT_IPHONE_CONTROL}
                />
              </AccordionContent>
            }
          />
          <Accordion
            title={t("help.accordion_3")}
            content={
              <AccordionContent>
                <ul>
                  <Item>{t("help.accordion_3_item_1")}</Item>
                  <Item>{t("help.accordion_3_item_2")}</Item>
                  <Item>{t("help.accordion_3_item_3")}</Item>
                  <Item>{t("help.accordion_3_item_4")}</Item>
                </ul>
              </AccordionContent>
            }
          />

          <DeviceHeader id="deviceAndroid">
            {t("help.android_title")}
          </DeviceHeader>
          <Accordion
            title={t("help.accordion_4")}
            content={
              <AccordionContent>
                <Text>{t("help.accordion_4_description")}</Text>
                <DeviceImage
                  className="withMargin"
                  alt="android control"
                  src={IMAGES.ABOUT_ANDROID_CONTROL}
                />
              </AccordionContent>
            }
          />
          <Support>
            <Text>
              {t("help.additional_support")}
              <Link href={t("help.additional_support_link") as string}>
                {t("help.additional_support_link_display")}
              </Link>
            </Text>
          </Support>
        </Devices>
      </Container>
    </Page>
  );

  return displayAbout ? AboutPage : <></>;
};

export default About;
