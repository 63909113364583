import { SVGProps } from "react";
import { NEUTRAL_200 } from "../../styles/colors";

const IconPoorConnection = ({
  fill = NEUTRAL_200,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3327 16.0001H15.3327C15.7009 16.0001 15.9993 16.2986 15.9993 16.6667V28.6667C15.9993 29.0349 15.7009 29.3334 15.3327 29.3334H11.3327C10.9645 29.3334 10.666 29.0349 10.666 28.6667V16.6667C10.666 16.2986 10.9645 16.0001 11.3327 16.0001ZM17.9993 9.33342H21.9993C22.3675 9.33342 22.666 9.63189 22.666 10.0001V28.6667C22.666 29.0349 22.3675 29.3334 21.9993 29.3334H17.9993C17.6312 29.3334 17.3327 29.0349 17.3327 28.6667V10.0001C17.3327 9.63189 17.6312 9.33342 17.9993 9.33342ZM24.666 2.66675H28.666C29.0342 2.66675 29.3327 2.96522 29.3327 3.33341V28.6667C29.3327 29.0349 29.0342 29.3334 28.666 29.3334H24.666C24.2978 29.3334 23.9993 29.0349 23.9993 28.6667V3.33341C23.9993 2.96522 24.2978 2.66675 24.666 2.66675Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66667 22.6667H8.66667C9.03486 22.6667 9.33333 22.9652 9.33333 23.3334V28.6667C9.33333 29.0349 9.03486 29.3334 8.66667 29.3334H4.66667C4.29848 29.3334 4 29.0349 4 28.6667V23.3334C4 22.9652 4.29848 22.6667 4.66667 22.6667Z"
        fill="white"
      />
    </svg>
  );
};
export default IconPoorConnection;
