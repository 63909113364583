import { HTMLAttributes } from "react";
import { TestId } from "../../utils/testId";
import {
  Spinner,
  SpinnerContainer,
  UpdatingText,
} from "./UpdatingIndicator.styles";

export type UpdatingIndicatorProps = {
  color?: "white";
  updatingText?: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const UpdatingIndicator = ({
  color = "white",
  updatingText,
  ...props
}: UpdatingIndicatorProps) => {
  return (
    <SpinnerContainer data-testid={TestId.UpdatingIndicator} {...props}>
      <Spinner />
      {updatingText && (
        <UpdatingText color={color}>{updatingText}</UpdatingText>
      )}
    </SpinnerContainer>
  );
};

export default UpdatingIndicator;
