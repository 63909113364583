import styled, { keyframes } from "styled-components";
import { IconLoading } from "../Icons";

export const SpinnerContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0px;
`;

const spin = keyframes`
 0% {
		transform: rotate(0deg);
	}
	
	100% {
		transform: rotate(360deg);
	}
`;

export const Spinner = styled(IconLoading)`
  animation: ${spin} 1s linear infinite;
`;

export const LoadingText = styled.div<{ color?: string }>`
  margin-top: 16px;
  color: ${({ theme, color }) => (color ? theme[color] : theme.white)};
`;
