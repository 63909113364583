import { SVGProps } from "react";
import { PRIMARY_500 } from "../../styles/colors";

const IconSearch = ({
  fill = PRIMARY_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.76 17.48L14.24 11.84C14.18 11.76 14.09 11.72 14.01 11.66C14.8 10.64 15.32 9.41 15.47 8.04C15.91 4.06 13.04 0.48 9.05002 0.04C8.79002 0.00999996 8.52002 0 8.25002 0C4.60002 0 1.45002 2.74 1.04002 6.45C0.600016 10.43 3.47002 14.01 7.46002 14.45C7.73002 14.48 8.00002 14.49 8.27002 14.49C9.41002 14.49 10.5 14.22 11.47 13.74C11.51 13.82 11.53 13.9 11.59 13.97L16.11 19.61C16.33 19.88 16.64 20.02 16.96 20.02C17.2 20.02 17.44 19.94 17.64 19.78L18.59 19.02C19.06 18.64 19.14 17.95 18.76 17.48ZM8.27002 13.49C8.04002 13.49 7.80002 13.48 7.57002 13.45C5.91002 13.27 4.42002 12.45 3.37002 11.15C2.33002 9.85 1.85002 8.22 2.03002 6.56C2.39002 3.39 5.06002 1 8.25002 1C8.48002 1 8.72002 1.01 8.95002 1.04C10.61 1.22 12.1 2.04 13.15 3.34C14.2 4.64 14.67 6.27 14.49 7.93C14.32 9.49 13.58 10.85 12.5 11.84L12.19 12.09C11.11 12.96 9.75002 13.49 8.27002 13.49ZM17.97 18.23L17.02 18.99C17.01 19 16.99 19.01 16.96 19.01C16.94 19.01 16.91 19 16.89 18.98L12.37 13.34C12.34 13.31 12.35 13.24 12.38 13.21L12.48 13.13C12.71 12.97 12.93 12.79 13.14 12.6L13.34 12.44C13.35 12.43 13.37 12.42 13.4 12.42C13.42 12.42 13.45 12.43 13.47 12.45L17.99 18.09C18.01 18.14 18 18.21 17.97 18.23Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconSearch;
