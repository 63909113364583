import { useContext, useEffect } from "react";
import { useHeap, usePrivacyCookie, useConsentManager } from "./";
import { I18nContext } from "../contexts";

export const useThirdPartyAnalytics = () => {
  const isTrackingAccepted = useConsentManager();
  const isHeapAllowed = usePrivacyCookie("analytics_heap");
  const { propertyCountry } = useContext(I18nContext);

  const { load } = useHeap();

  useEffect(() => {
    const isEU = () => {
      const EU = ["GB", "DE", "ES", "IT", "FR"];

      return EU.includes(propertyCountry.toUpperCase());
    };

    /**
     * Heap will be enabled when one of the following use cases ocurrs:
     * - User clicks the "accept" button in the consent manager banner
     * - User has allowed heap tracking before (we can check this through the privacy cookie)
     * - User is not in EU/UK
     */
    if (isHeapAllowed || isTrackingAccepted || (propertyCountry && !isEU())) {
      load();
    }
  }, [isHeapAllowed, isTrackingAccepted, load, propertyCountry]);
};

export default useThirdPartyAnalytics;
