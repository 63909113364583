import { SVGProps } from "react";
import { NEUTRAL_500 } from "../../styles/colors";

const IconCamera = ({
  fill = NEUTRAL_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="12"
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0813 1.37994C20.0813 1.37994 20.0783 1.37994 20.0763 1.37994C19.9823 1.37994 19.8943 1.40594 19.8193 1.45094L19.8213 1.44994L16.6113 3.38994V0.999939C16.6113 0.447939 16.1633 -6.10352e-05 15.6113 -6.10352e-05H1.61133C1.05933 -6.10352e-05 0.611328 0.447939 0.611328 0.999939V10.9999C0.611328 11.5519 1.05933 11.9999 1.61133 11.9999H15.6113C16.1633 11.9999 16.6113 11.5519 16.6113 10.9999V8.59994L19.8213 10.5399C19.8943 10.5839 19.9823 10.6099 20.0763 10.6099C20.0783 10.6099 20.0793 10.6099 20.0813 10.6099C20.3573 10.6099 20.5813 10.3859 20.5813 10.1099V1.87994C20.5813 1.60394 20.3573 1.37994 20.0813 1.37994ZM15.6113 10.9999H1.61133V0.999939H15.6113V10.9999ZM19.6113 9.22994L16.6113 7.43994V4.55994L19.6113 2.76994V9.22994ZM13.6113 7.99994C13.0593 7.99994 12.6113 8.44794 12.6113 8.99994C12.6113 9.55194 13.0593 9.99994 13.6113 9.99994C14.1633 9.99994 14.6113 9.55194 14.6113 8.99994C14.6113 8.44794 14.1633 7.99994 13.6113 7.99994Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconCamera;
