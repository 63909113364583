import { HTMLAttributes } from "react";
import {
  CloseButton,
  Header,
  ModalBrowser,
  Body,
  Content,
  Icons,
} from "./BrowserNotSupportedModal.styles";
import { useTranslation } from "react-i18next";
import { IconBrowser, IconChrome, IconClose, IconSafari } from "..";
import { TestId } from "../../utils";

export type BrowserNotSupportedModalProps = {
  onClose?: () => void;
} & HTMLAttributes<HTMLDivElement>;

const BrowserNotSupportedModal = ({
  onClose,
  ...props
}: BrowserNotSupportedModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalBrowser
      data-testid={TestId.BrowserNotSupported}
      role="dialog"
      aria-label="dialog"
      {...props}
    >
      <Header>
        <CloseButton aria-label="Close" onClick={onClose}>
          <IconClose />
        </CloseButton>
      </Header>

      <Content>
        <Body>
          <IconBrowser width={48} height={48} />
          <h3>{t("browser_not_supported.title")}</h3>
          <p>{t("browser_not_supported.detail")}</p>
          <Icons>
            <IconChrome width={70} height={70} />
            <IconSafari width={70} height={70} />
          </Icons>
        </Body>
      </Content>
    </ModalBrowser>
  );
};

export default BrowserNotSupportedModal;
