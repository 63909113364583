import { SVGProps } from "react";
import { NEUTRAL_500 } from "../../styles/colors";

const IconRotate = ({
  fill = NEUTRAL_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.11233 8.498V8.494C3.11233 4.352 6.47033 0.994 10.6123 0.994C12.9883 0.994 15.1063 2.099 16.4803 3.823L16.4923 3.838C16.5833 3.973 16.7353 4.061 16.9083 4.061C17.1843 4.061 17.4083 3.837 17.4083 3.561C17.4083 3.428 17.3563 3.307 17.2713 3.218C15.7003 1.25 13.3023 0 10.6113 0C5.91733 0 2.11233 3.805 2.11133 8.498H0.611328L2.61133 10.498L4.61133 8.498H3.11233ZM18.1123 8.498V8.502C18.1123 12.644 14.7543 16.002 10.6123 16.002C8.23633 16.002 6.11833 14.897 4.74433 13.173L4.73233 13.158C4.64133 13.023 4.48933 12.935 4.31633 12.935C4.04033 12.935 3.81633 13.159 3.81633 13.435C3.81633 13.568 3.86833 13.689 3.95333 13.778C5.52433 15.746 7.92233 16.996 10.6133 16.996C15.3073 16.996 19.1123 13.191 19.1133 8.498H20.6133L18.6133 6.498L16.6133 8.498H18.1123Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconRotate;
