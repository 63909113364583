import { SVGProps } from "react";
import { PRIMARY_500 } from "../../styles/colors";

const IconOffline = ({
  fill = PRIMARY_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38 11.8004L42.2 7.60039C42.6 7.20039 42.6 6.60039 42.2 6.20039C41.8 5.80039 41.2 5.80039 40.8 6.20039L8.2 38.8004C7.8 39.2004 7.8 39.8004 8.2 40.2004C8.6 40.6004 9.2 40.6004 9.6 40.2004L21 28.8004C22 28.4004 23 28.2004 24 28.2004C26.4 28.2004 28.8 29.0004 30.4 30.6004L32.6 28.2004C30.6 26.4004 27.8 25.0004 24.8 24.8004L28.8 20.8004C31.6 21.6004 34 23.0004 36 24.8004L38.2 22.2004C36.2 20.4004 34 19.0004 31.6 18.0004L35 14.6004C39 16.6004 41.4 19.0004 41.4 19.0004L44 16.2004C44 16.2004 41.8 14.0004 38 11.8004Z"
        fill="#D4231A"
      />
      <path
        d="M34.2 10.0004L31 13.2004C29 12.6004 26.6 12.2004 24.2 12.2004C13.4 12.2004 6.8 19.4004 6.8 19.4004L4 16.2004C4 16.2004 12 8.00039 24 8.00039C28 8.00039 31.4 8.80039 34.2 10.0004Z"
        fill="#D4231A"
      />
      <path
        d="M24 39.0004C25.6 39.0004 27 37.6004 27 36.0004C27 34.4004 25.6 33.0004 24 33.0004C22.4 33.0004 21 34.4004 21 36.0004C21 37.6004 22.4 39.0004 24 39.0004Z"
        fill="#D4231A"
      />
      <path
        d="M12 25.2004L9.8 22.4004C13.6 19.0004 18.6 16.4004 24 16.4004C25.0476 16.4004 25.9427 16.5528 26.9516 16.7246C27.0984 16.7496 27.2476 16.775 27.4 16.8004L23.8 20.4004C19.2 20.4004 15 22.4004 12 25.2004Z"
        fill="#D4231A"
      />
    </svg>
  );
};
export default IconOffline;
