import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Header, OopsError, ResidentDirectory, Timer } from "../../components";
import { Page, StyledLoadingIndicator } from "./Directory.styles";
import { ROUTES } from "../../utils/routes";
import { useHeap, usePermissions, useSessionExpiration } from "../../hooks";
import {
  getCameraPermission,
  getMicrophonePermission,
} from "../../utils/permissions";
import { TestId } from "../../utils/testId";
import { Resident } from "../../api";
import { metricEvents } from "../../utils/constants";
import { MetricBaseData, getMetricsBaseData } from "../../utils";
import { useResidentsList } from "../../hooks/queries";
import { I18nContext } from "../../contexts";

const Directory = () => {
  const { data, error, isLoading } = useResidentsList();
  useSessionExpiration({ redirectOnSessionTimeout: true, data });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [initialMetricLogged, setInitialMetricLogged] = useState(false);
  const location = useLocation();
  const { track } = useHeap();
  const { setLocationLanguage } = useContext(I18nContext);

  useEffect(() => {
    if (
      error &&
      (error.response?.status === 400 || error.response?.status === 404)
    ) {
      setShowError(true);
    } else if (
      error &&
      (error.response?.status === 500 || error.response?.status === 406)
    ) {
      track(metricEvents.apNotAvailable);
      navigate(
        {
          pathname: `../${ROUTES.DISABLED_SERVICE}`,
        },
        { replace: true }
      );
    }
  }, [error, track, navigate]);

  useEffect(() => {
    if (data && !initialMetricLogged) {
      setInitialMetricLogged(true);
      track(
        metricEvents.property,
        getMetricsBaseData({
          propertyId: data?.propertyId,
          accessPointId: data?.accessPointId,
        })
      );
    }
  }, [data, initialMetricLogged, track]);

  useEffect(() => {
    if (data) {
      setLocationLanguage(data.locale);
    }
  }, [setLocationLanguage, data]);

  const getMetricData = (contact: Resident) => {
    return getMetricsBaseData({
      propertyId: data?.propertyId,
      accessPointId: data?.accessPointId,
      contact,
    });
  };

  const handleCall = (contact: Resident) => {
    navigate(
      {
        pathname: `../${ROUTES.LIVE}`,
        search: location.search,
      },
      {
        state: {
          propertyId: data?.propertyId,
          accessPointId: data?.accessPointId,
          contact,
        } as MetricBaseData,
      }
    );
  };

  const callFrontDesk = () => {
    console.log("Call front desk");
  };

  const handleCamera = async (contact: Resident) => {
    try {
      await getCameraPermission();
      track(metricEvents.cameraPermissionGranted, getMetricData(contact));
    } catch {
      track(metricEvents.cameraPermissionDenied, getMetricData(contact));
    } finally {
      handleCall(contact);
    }
  };

  const handlePermissions = async (contact: Resident) => {
    if (permissions.microphone === "granted") {
      await handleCamera(contact);
    } else {
      try {
        const micPermission = await getMicrophonePermission();

        if (micPermission) {
          track(metricEvents.micPermissionGranted, getMetricData(contact));
          await handleCamera(contact);
        }
      } catch (err) {
        track(metricEvents.micPermissionDenied, getMetricData(contact));
        setShowAlert(true);
      }
    }
  };

  const handleModalStatus = (contact: Resident, status: boolean) => {
    if (status) {
      track(metricEvents.callResident, getMetricData(contact));
      handlePermissions(contact);
    } else {
      track(metricEvents.cancelCall, getMetricData(contact));
    }
  };

  useEffect(() => {
    if (permissions.microphone === "denied") {
      setShowAlert(true);
    } else if (permissions.microphone === "granted") {
      setShowAlert(false);
    }
  }, [permissions]);

  return (
    <Page data-testid={TestId.Directory}>
      <Header />
      {isLoading ? (
        <StyledLoadingIndicator color="neutral500" />
      ) : (
        <>
          {data && (
            <>
              <Timer />
              <ResidentDirectory
                data={data}
                showAlert={showAlert}
                handleResidentCall={handleModalStatus}
                callFrontDesk={callFrontDesk}
              />
            </>
          )}
          {showError && (
            <OopsError
              title={t("not_found.title")}
              subtitle={t("not_found.subtitle")}
              testId={TestId.NotFound}
              image="404"
              buttonText={t("not_found.button")}
              buttonAction={() => console.log("contact support")}
              buttonVariant="tertiary"
            />
          )}
        </>
      )}
    </Page>
  );
};

export default Directory;
