import { FC } from "react";
import { Header, ServiceDisabled } from "../../components";

const DisabledService: FC = () => {
  return (
    <>
      <Header />
      <ServiceDisabled />
    </>
  );
};

export default DisabledService;
