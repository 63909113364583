import { SVGProps } from "react";
import { PRIMARY_500 } from "../../styles/colors";

const IconCallBusy = ({
  fill = PRIMARY_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.4628 12.1685C28.627 12.0586 28.82 12 29.0175 12C29.2823 12 29.5362 12.1054 29.7235 12.2929C29.9107 12.4804 30.0159 12.7348 30.0159 13C30.0159 13.1978 29.9574 13.3911 29.8476 13.5556C29.7379 13.72 29.582 13.8482 29.3996 13.9239C29.2171 13.9996 29.0164 14.0194 28.8227 13.9808C28.629 13.9422 28.4511 13.847 28.3115 13.7071C28.1719 13.5673 28.0768 13.3891 28.0383 13.1951C27.9997 13.0011 28.0195 12.8 28.0951 12.6173C28.1707 12.4346 28.2986 12.2784 28.4628 12.1685Z"
        fill="#1998D5"
      />
      <path
        d="M28.3115 4.29289C28.4988 4.10536 28.7527 4 29.0175 4C29.2823 4 29.5362 4.10536 29.7235 4.29289C29.9107 4.48043 30.0159 4.73478 30.0159 5V9C30.0159 9.26522 29.9107 9.51957 29.7235 9.70711C29.5362 9.89464 29.2823 10 29.0175 10C28.7527 10 28.4988 9.89464 28.3115 9.70711C28.1243 9.51957 28.0191 9.26522 28.0191 9V5C28.0191 4.73478 28.1243 4.48043 28.3115 4.29289Z"
        fill="#1998D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0032 0H20.0318C19.5022 0 18.9943 0.210713 18.6198 0.585786C18.2454 0.960859 18.035 1.46957 18.035 2V16C18.035 16.5304 18.2454 17.0391 18.6198 17.4142C18.9943 17.7893 19.5022 18 20.0318 18H22.0286V24L29.6165 18H38.0032C38.5328 18 39.0407 17.7893 39.4151 17.4142C39.7896 17.0391 40 16.5304 40 16V2C40 1.46957 39.7896 0.960859 39.4151 0.585786C39.0407 0.210713 38.5328 0 38.0032 0ZM38.0032 16H28.8777L24.0254 20V16H20.0318V2H38.0032V16Z"
        fill="#1998D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.243325 14.22C1.28167 10.72 5.73458 6 7.7314 6H8.05089C9.9279 6.54 12.0445 9.68 12.1643 10C13.1098 11.4118 13.6739 13.0449 13.8017 14.74C13.6876 15.4524 13.1294 16.4355 12.5652 17.4291C11.8464 18.695 11.1179 19.978 11.2857 20.74C11.8784 22.6449 12.9674 24.357 14.4407 25.7C15.7815 27.1756 17.491 28.2664 19.3928 28.86H19.6324C20.4064 28.86 21.5739 28.1935 22.7174 27.5406C23.6981 26.9807 24.6612 26.4308 25.3433 26.32H25.6628C27.2156 26.5672 28.6989 27.1397 30.0159 28C30.3753 28.12 33.5103 30 34.0095 32C34.5087 34 29.4768 38.66 25.8226 39.76C25.2371 39.9208 24.6326 40.0016 24.0254 40C18.674 40 12.0445 34.16 8.88955 31.1C5.47499 27.7 -1.3741 19.76 0.243325 14.22ZM10.507 11.04C9.79829 9.8478 8.85376 8.81329 7.7314 8C5.18842 9.58875 3.23779 11.9712 2.18024 14.78C1.04205 18.66 5.85439 25.26 10.3472 29.68C14.3608 33.7 20.0318 38 24.0254 38C24.4282 38.0096 24.8306 37.9693 25.2235 37.88C28.0353 36.8245 30.4214 34.8704 32.0127 32.32C31.2083 31.2144 30.1896 30.2825 29.0175 29.58C28.022 28.9048 26.9018 28.4356 25.7227 28.2C25.0261 28.459 24.3572 28.7873 23.7259 29.18C22.5441 30.0265 21.1726 30.569 19.7323 30.76C19.5137 30.7906 19.2919 30.7906 19.0733 30.76C16.7617 30.1429 14.6799 28.864 13.0829 27.08C11.3061 25.4767 10.0302 23.393 9.40872 21.08C9.33146 19.3783 9.86245 17.7048 10.9063 16.36C11.2948 15.7258 11.6224 15.0561 11.8848 14.36C11.6745 13.1654 11.2041 12.0319 10.507 11.04Z"
        fill="#1998D5"
      />
    </svg>
  );
};
export default IconCallBusy;
