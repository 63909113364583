export interface Environment {
  basePath?: string;
  heapId?: string;
  consentScriptURL?: string;
  basename?: string;
  displayAbout?: boolean;
  stage?: string;
  loggerUrl: string;
  socketUrl: string;
  mapOFF?: boolean;
  hideLocalization?: boolean;
  browserLanguage?: string;
  displayFeedback?: boolean;
}

export const environment = (window as any).ENVIRONMENT as Environment;
