import styled from "styled-components";

export const CallingBox = styled.div`
  min-height: calc(var(--app-height) - 128px);
  display: flex;
  position: relative;
  background: ${({ theme }) => theme.neutral500};
  flex-direction: column;
  color: white;
  text-align: center;
`;

export const ResidentMedia = styled.div`
  flex-grow: 1;
  height: calc(var(--app-height) / 2 - 108px);
  background-color: ${({ theme }) => theme.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UserName = styled.div`
  margin-top: 16px;
  font-size: 1.25rem;
`;

export const ConnectedText = styled.div`
  font-size: 0.75rem;
`;
