import {
  NEUTRAL_300,
  NEUTRAL_50,
  NEUTRAL_500,
  NEUTRAL_600,
  PRIMARY_500,
  TERTIARY_300,
  TERTIARY_500,
  TERTIARY_700,
  PRIMARY_100,
  PRIMARY_400,
  WHITE,
  PRIMARY_700,
  BLACK,
  SECONDARY_500,
  BLACK_65,
  NEUTRAL_200,
  PRIMARY_200,
  NEUTRAL_100,
  POSITIVE_500,
  POSITIVE_100,
  NEUTRAL_700,
} from "./colors";

export const theme = {
  primary: PRIMARY_500,
  primary100: PRIMARY_100,
  primary400: PRIMARY_400,
  primary700: PRIMARY_700,
  primary200: PRIMARY_200,
  secondary500: SECONDARY_500,
  neutral500: NEUTRAL_500,
  neutral50: NEUTRAL_50,
  neutral100: NEUTRAL_100,
  neutral200: NEUTRAL_200,
  neutral300: NEUTRAL_300,
  neutral600: NEUTRAL_600,
  neutral700: NEUTRAL_700,
  tertiary300: TERTIARY_300,
  tertiary500: TERTIARY_500,
  tertiary700: TERTIARY_700,
  white: WHITE,
  black: BLACK,
  black65: BLACK_65,
  positive500: POSITIVE_500,
  positive100: POSITIVE_100,
};
