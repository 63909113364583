import logger from "./logger";

export const hashSessionId = async (sessionId: string) => {
  try {
    const textAsBuffer = new TextEncoder().encode(sessionId);
    const hashBuffer = await window.crypto.subtle.digest(
      "SHA-256",
      textAsBuffer
    );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray
      .map((item) => item.toString(16).padStart(2, "0"))
      .join("");
    return hash;
  } catch (error: any) {
    logger.error("Failed to hash sessionId", error);
  }
};
