import styled from "styled-components";

export const Content = styled.div`
  margin-left: 16px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 0px;
`;

export const Subtitle = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;
