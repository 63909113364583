import { FunctionComponent } from "react";
import { Container, OopsImage } from "./OopsError.styles";
import { IMAGES } from "../../utils/imagesPaths";
import { TestId } from "../../utils/testId";
import Button from "../Button/Button";

interface OopsErrorProps {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  testId: TestId;
  image?: "location" | "404";
  buttonText?: React.ReactNode;
  buttonVariant?: "tertiary" | "secondary";
  buttonAction?: () => void;
}

const OopsError: FunctionComponent<OopsErrorProps> = ({
  title,
  subtitle,
  testId,
  buttonText,
  image = "location",
  buttonVariant = "secondary",
  buttonAction,
}) => {
  return (
    <Container data-testid={testId}>
      <OopsImage
        src={image === "location" ? IMAGES.OOPS : IMAGES.OOPS_404}
        alt="oops"
      />
      <h3>{title}</h3>
      <p>{subtitle}</p>
      {buttonText && buttonAction && (
        <Button variant={buttonVariant} onClick={buttonAction}>
          {buttonText}
        </Button>
      )}
    </Container>
  );
};

export default OopsError;
