import styled from "styled-components";

export const Select = styled.select`
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  color: ${({ theme }) => theme.neutral500};
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 4px;
  padding: 4px 22px 4px 8px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  z-index: 1;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const Widget = styled.div`
  display: flex;
  position: relative;
  height: 32px;
  > svg {
    position: absolute;
    right: 6px;
    top: 8px;
  }
`;
