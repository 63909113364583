import cookies from "js-cookie";

import { CC_PRIVACY_COOKIE, PRIVACY_COOKIE } from "../utils";

export interface UserPrivacyPreferences {
  analytics_heap: boolean | string;
}

export const usePrivacyCookie = (
  analyticsProvider: keyof UserPrivacyPreferences
) => {
  const privacyCookie =
    cookies.get(CC_PRIVACY_COOKIE) ?? cookies.get(PRIVACY_COOKIE);

  // analytics provider will be enabled by default if
  // there're no user's custom preferences
  if (!privacyCookie) {
    return null;
  }

  const settings = JSON.parse(privacyCookie) as UserPrivacyPreferences;

  return settings[analyticsProvider]?.toString() === "true";
};

export default usePrivacyCookie;
