import { useTranslation } from "react-i18next";
import { HTMLAttributes } from "react";
import { Content, Subtitle, Title } from "./PermissionAlert.styles";
import { IconDisabledMicrophone } from "../Icons";
import { TestId } from "../../utils/testId";
import { StyledBox } from "../../App.styles";

export type PermissionAlertProps = {} & HTMLAttributes<HTMLDivElement>;

const PermissionAlert = ({ ...props }: PermissionAlertProps) => {
  const { t } = useTranslation();
  return (
    <StyledBox data-testid={TestId.PermissionAlert} {...props}>
      <IconDisabledMicrophone />
      <Content>
        <Title>{t("permission_alert.title")}</Title>
        <Subtitle>{t("permission_alert.subtitle")}</Subtitle>
      </Content>
    </StyledBox>
  );
};

export default PermissionAlert;
