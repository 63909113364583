import KatalLogger from "@katal/logger";
import { environment } from "./environment";

const logger = new KatalLogger({
  url: environment.loggerUrl,
});

const originalError = console.error;
console.error = (...data) => {
  originalError(...data);
  logger.error(...data);
};

export default logger;
