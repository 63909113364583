import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: ${({ theme }) => theme.tertiary300};
  padding: 0px 24px;

  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin: 16px 0px 0px;
  }

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin: 0px 0px 24px;
  }
`;

export const OopsImage = styled.img`
  max-width: 327px;
`;
