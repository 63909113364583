import { HTMLAttributes } from "react";
import { Content, Container } from "./ServiceDisabled.styles";
import { TestId } from "../../utils/testId";
import { IconLockWarning } from "../Icons";
import { NEGATIVE_500 } from "../../styles/colors";
import { useTranslation } from "react-i18next";

export type ServiceDisabledProps = {
  onClick?: () => void;
} & HTMLAttributes<HTMLDivElement>;

const ServiceDisabled = (props: ServiceDisabledProps) => {
  const { t } = useTranslation();
  return (
    <Container data-testid={TestId.ServiceDisabled} {...props}>
      <Content>
        <IconLockWarning fill={NEGATIVE_500} />
        <h2>{t("service_disabled.title")}</h2>
        <p>{t("service_disabled.detail")}</p>
      </Content>
      {/* <BottomContent>
        <Button fullWidth onClick={onClick}>
          {t("service_disabled.try_again")}
        </Button>
      </BottomContent> */}
    </Container>
  );
};

export default ServiceDisabled;
