import { SVGProps } from "react";
import { PRIMARY_500 } from "../../styles/colors";

const IconBrowser = ({
  fill = PRIMARY_500,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.48 26.48L17 31L21.52 21.52L31 17L26.48 26.48ZM34 14L20 20L14 34L28 28L34 14Z"
        fill={fill}
      />
      <path
        d="M24 6C27.5601 6 31.0402 7.05568 34.0003 9.03355C36.9604 11.0114 39.2675 13.8226 40.6298 17.1117C41.9922 20.4008 42.3487 24.02 41.6541 27.5116C40.9596 31.0033 39.2453 34.2106 36.7279 36.7279C34.2106 39.2453 31.0033 40.9596 27.5116 41.6541C24.02 42.3487 20.4008 41.9922 17.1117 40.6298C13.8226 39.2675 11.0114 36.9603 9.03356 34.0003C7.05569 31.0402 6.00001 27.5601 6.00001 24C6.00001 19.2261 7.89643 14.6477 11.2721 11.2721C14.6477 7.89642 19.2261 6 24 6ZM24 4C20.0444 4 16.1776 5.17298 12.8886 7.37061C9.59962 9.56824 7.03617 12.6918 5.52242 16.3463C4.00867 20.0009 3.6126 24.0222 4.3843 27.9018C5.15601 31.7814 7.06082 35.3451 9.85787 38.1421C12.6549 40.9392 16.2186 42.844 20.0982 43.6157C23.9778 44.3874 27.9992 43.9913 31.6537 42.4776C35.3082 40.9638 38.4318 38.4004 40.6294 35.1114C42.827 31.8224 44 27.9556 44 24C44 21.3736 43.4827 18.7728 42.4776 16.3463C41.4725 13.9198 39.9993 11.715 38.1421 9.85786C36.285 8.00069 34.0802 6.5275 31.6537 5.52241C29.2272 4.51732 26.6264 4 24 4Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconBrowser;
