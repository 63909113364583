import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { SessionTokenContext } from "./SessionTokenContext";
import { environment } from "../utils";
import { useLogger } from "../hooks/useLogger";

// to do: add all pending actions
export enum WebSocketAction {
  createCall = "createCall",
  createCallV2 = "createCallV2",
  startCall = "startCall",
  endCall = "endCall",
}

export type WebSocketKey = {
  residentId?: string;
  sdpAnswer?: string | undefined;
};

export enum WebSocketConnectionStatus {
  Connecting = "Connecting",
  Open = "Open",
  Closed = "Closed",
  Uninitiated = "Uninitiated",
}

export enum WebSocketMessage {
  ResidentConnected = "resident_connected",
  ClientDisconnect = "client_disconnect",
  SessionEnded = "session_ended",
  ResidentRateLimit = "Exceeded call limitation",
  ApOngoingCall = "Ongoing call exists for current access point",
  ResidentOngoingCall = "DoorbotId already exist in redis",
  ResidentOngoingCallMAP = "Resident Already on Another Call",
  InvalidSession = "Invalid session state for CreateCallWs",
  ExistingSession = "There is existing video streaming session and cannot create another one",
  InternalServerError = "Internal server error",
  DingCreateError = "Failed to create call for doorbotId",
}

export interface SocketContextProps {
  messageHistory: any[];
  webSocket?: WebSocket;
  connectWS: (residentId: string, onError: (error: any) => void) => void;
  sendMessage: (action: WebSocketAction, otherData?: WebSocketKey) => void;
  connectionStatus: WebSocketConnectionStatus;
}

export const SocketContext = createContext<SocketContextProps>({
  connectionStatus: WebSocketConnectionStatus.Uninitiated,
  messageHistory: [],
  connectWS: (residentId: string, onError: (error: any) => void) => {},
  sendMessage: (action: WebSocketAction, otherData?: WebSocketKey) => null,
});

export const SocketProvider = ({ children }: PropsWithChildren<{}>) => {
  const { sessionTokenWrapper } = useContext(SessionTokenContext);
  const logger = useLogger();
  const [sessionToken] = sessionTokenWrapper;
  const [messageHistory, setMessageHistory] = useState<any[]>([]);
  const [connectionStatus, setConnectionStatus] =
    useState<WebSocketConnectionStatus>(WebSocketConnectionStatus.Uninitiated);
  const webSocket = useRef<WebSocket>();
  const { mapOFF } = environment;

  const onMessage = useCallback(
    (event: MessageEvent) => {
      try {
        const message = JSON.parse(event.data);
        logger.info("Incoming WS message", { message });
        setMessageHistory((prev) => [...prev, message]);
      } catch (error) {
        logger.error("Error parsing web socket message", {
          message: event.data,
        });
      }
    },
    [logger]
  );

  const sendMessage = useCallback(
    (action: WebSocketAction, otherData?: WebSocketKey) => {
      logger.info("Send WS message", {
        message: action,
        otherData,
      });
      webSocket.current?.send(
        JSON.stringify({
          action: action,
          ...otherData,
        })
      );
    },
    [webSocket, logger]
  );

  const connectWS = useCallback(
    (residentId: string, onError: (error: any) => void) => {
      if (sessionToken.length > 0) {
        logger.info("Initiating WS connection");
        const url = `${environment.socketUrl}/call?session=${sessionToken}`;
        webSocket.current = new WebSocket(url);
        setConnectionStatus(WebSocketConnectionStatus.Connecting);

        // Create call when connection is open
        webSocket.current.onopen = (event) => {
          logger.info("WS connection opened");
          setConnectionStatus(WebSocketConnectionStatus.Open);
          sendMessage(
            mapOFF ? WebSocketAction.createCall : WebSocketAction.createCallV2,
            { residentId }
          );
        };

        webSocket.current.onmessage = onMessage;
        webSocket.current.onerror = onError;
        webSocket.current.onclose = (event) => {
          logger.info("WS connection closed");
          setConnectionStatus(WebSocketConnectionStatus.Closed);
        };
      }
    },
    [sessionToken, onMessage, sendMessage, mapOFF, logger]
  );

  return (
    <SocketContext.Provider
      value={{
        webSocket: webSocket.current,
        connectionStatus,
        messageHistory,
        connectWS,
        sendMessage,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
