import { HTMLAttributes } from "react";
import { Input, Search } from "./SearchBox.styles";
import { IconSearch } from "../Icons";
import { TestId } from "../../utils/testId";

export type SearchBoxProps = {
  withError?: boolean;
} & HTMLAttributes<HTMLInputElement>;

const SearchBox = ({ withError = false, ...props }: SearchBoxProps) => {
  return (
    <Search>
      <IconSearch />
      <Input data-testid={TestId.SearchBox} withError={withError} {...props} />
    </Search>
  );
};

export default SearchBox;
