import { SVGProps } from "react";
import { WHITE } from "../../styles/colors";

const IconCameraLarge = ({
  fill = WHITE,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="36"
      viewBox="0 0 40 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 22.9999C24 21.7999 24.8 20.9999 26 20.9999C27.2 20.9999 28 21.7999 28 22.9999C28 24.1999 27.2 24.9999 26 24.9999C24.8 24.9999 24 24.1999 24 22.9999Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39 7.7999C38.8 7.7999 38.6 7.7999 38.4 7.9999L32 11.7999V6.5999L36 2.5999C36.4 2.1999 36.4 1.5999 36 1.1999C35.6 0.799902 35 0.799902 34.6 1.1999L2 33.5999C1.6 33.9999 1.6 34.5999 2 34.9999C2.4 35.3999 3 35.3999 3.4 34.9999L9.4 28.9999H30C31.2 28.9999 32 27.9999 32 26.9999V22.1999L38.4 25.9999C38.5 25.9999 38.6 26.0499 38.7 26.0999C38.8 26.1499 38.9 26.1999 39 26.1999C39.6 26.1999 40 25.7999 40 25.1999V8.7999C40 8.1999 39.4 7.7999 39 7.7999ZM11.4 26.9999H30V8.3999L11.4 26.9999ZM38 23.3999L32 19.7999V13.9999L38 10.3999V23.3999Z"
        fill={fill}
      />
      <path
        d="M2 28.9999H3.8L5.8 26.9999H2V6.9999H25.8L27.8 4.9999H2C0.8 4.9999 0 5.9999 0 6.9999L0 26.9999C0 27.9999 0.8 28.9999 2 28.9999Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconCameraLarge;
