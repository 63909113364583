import { useContext, useEffect } from "react";
import { useBoolean } from "@amzn/ring-ui-react-components";
import { environment } from "../utils";
import { I18nContext } from "../contexts";
import { Languages } from "./useI18n";

const CM_SCRIPT_TAG_ID = "consent-script";
const CM_BANNER_BUTTON_ACCEPT_CLASSNAME = "consent--accept";
const CM_BODY_SELECTOR = ".consent-body";
const CM_BANNER_SELECTOR = ".consent-banner";

export type LocaleConsentManagerType = "en-EU" | "en-US";

const useConsentManager = () => {
  const [isTrackingAccepted, { on: setIsTrackingAcceptedOn }] = useBoolean();
  const { consentScriptURL } = environment;
  const { propertyCountry, locationLanguage, selectedLng } =
    useContext(I18nContext);

  useEffect(() => {
    const isEU = () => {
      const EU = ["GB", "DE", "ES", "IT", "FR"];

      return EU.includes(propertyCountry.toUpperCase());
    };

    const existingScript = document.getElementById(CM_SCRIPT_TAG_ID);
    if (existingScript) {
      document.head.removeChild(existingScript);
    }

    const consentBody = document.querySelector(CM_BODY_SELECTOR);
    if (consentBody) {
      document.body.removeChild(consentBody);
    }

    const consentBanner = document.querySelector(CM_BANNER_SELECTOR);
    if (consentBanner) {
      document.body.removeChild(consentBanner);
    }

    if (consentScriptURL && selectedLng && propertyCountry) {
      const localeDictionary = {
        [Languages.de]: "de-DE",
        [Languages.en]: "en-GB",
        [Languages.es]: "es-ES",
        [Languages.fr]: "fr-FR",
        [Languages.frCA]: "fr-FR",
        [Languages.it]: "it-IT",
        [Languages.ja]: "ja-JP",
      };
      const scriptLocale = localeDictionary[selectedLng];

      sessionStorage.removeItem("consentStorage");

      const localeSettings = isEU()
        ? `locale=${scriptLocale}&loggedIn=0&region=EU`
        : `locale=${scriptLocale}&loggedIn=0`;

      const script = document.createElement("script");

      script.id = CM_SCRIPT_TAG_ID;
      script.type = "text/javascript";
      script.src = `${consentScriptURL}?${localeSettings}`;
      script.async = true;

      document.head.appendChild(script);
    }

    const handleClickAccept = ({ target }: MouseEvent) => {
      if (
        target instanceof HTMLElement &&
        target.className.includes(CM_BANNER_BUTTON_ACCEPT_CLASSNAME)
      ) {
        setIsTrackingAcceptedOn();
      }
    };

    document.addEventListener("click", handleClickAccept);

    return () => {
      document.removeEventListener("click", handleClickAccept);
    };
  }, [
    setIsTrackingAcceptedOn,
    consentScriptURL,
    locationLanguage,
    propertyCountry,
    selectedLng,
  ]);

  return isTrackingAccepted;
};

export default useConsentManager;
